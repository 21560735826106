import { memo } from "react";
const SvgComponent = () => (
  <svg
    width="1800"
    height="1720"
    viewBox="0 0 1800 1720"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M818.505 101.568L867.953 100.238C870.763 100.163 873.107 102.384 873.183 105.194L883.952 505.76L829.411 507.226L818.505 101.568Z"
      fill="currentColor"
    />
    <path
      d="M829.45 509.343C828.275 509.343 827.307 508.412 827.279 507.228L816.348 101.573C816.32 100.37 817.26 99.3741 818.463 99.3459L867.911 98.0112C867.977 98.0112 868.042 98.0112 868.108 98.0112C872.027 98.0112 875.261 101.141 875.373 105.079L886.173 505.64C886.201 506.833 885.261 507.839 884.058 507.867L829.516 509.333C829.516 509.333 829.479 509.333 829.46 509.333L829.45 509.343ZM820.747 103.632L831.565 504.944L881.765 503.591L871.022 105.201C870.975 103.594 869.65 102.316 868.024 102.354L820.747 103.632Z"
      fill="currentColor"
    />
    <path
      d="M891.445 107.574L836.904 109.041L847.81 514.699L902.351 513.233L891.445 107.574Z"
      fill="white"
    />
    <path
      d="M847.844 516.815C846.67 516.815 845.711 515.884 845.673 514.7L834.742 109.046C834.724 108.472 834.94 107.908 835.334 107.495C835.729 107.081 836.274 106.837 836.848 106.818L891.39 105.352C891.39 105.352 891.427 105.352 891.446 105.352C892.621 105.352 893.589 106.282 893.617 107.467L904.548 513.121C904.567 513.694 904.351 514.258 903.956 514.672C903.561 515.086 903.016 515.33 902.443 515.349L847.901 516.815C847.901 516.815 847.863 516.815 847.835 516.815H847.844ZM839.141 111.104L849.959 512.416L900.159 511.063L889.341 109.75L839.141 111.104Z"
      fill="currentColor"
    />
    <path
      d="M819.018 102.025L836.913 108.989L890.609 107.824L876.107 101.79C872.977 100.483 869.593 99.9005 866.21 100.06L819.027 102.015L819.018 102.025Z"
      fill="white"
    />
    <path
      d="M836.913 111.16C836.64 111.16 836.377 111.113 836.124 111.01L818.228 104.045C817.269 103.669 816.705 102.683 816.875 101.668C817.044 100.652 817.899 99.9005 818.924 99.8535L866.106 97.8986C869.79 97.72 873.522 98.3779 876.924 99.7877L891.427 105.822C892.376 106.217 892.921 107.222 892.724 108.228C892.536 109.234 891.662 109.976 890.637 109.995L836.941 111.16C836.941 111.16 836.913 111.16 836.894 111.16H836.913ZM829.469 103.763L837.298 106.809L880.27 105.878L875.27 103.801C872.45 102.626 869.339 102.09 866.313 102.241L829.469 103.773V103.763Z"
      fill="currentColor"
    />
    <path
      d="M1017.97 128.571L1067.21 133.303C1070 133.572 1072.06 136.064 1071.79 138.862L1033.45 537.734L979.142 532.514L1017.97 128.571Z"
      fill="currentColor"
    />
    <path
      d="M1033.43 539.955C1033.36 539.955 1033.28 539.955 1033.22 539.946L978.903 534.72C978.329 534.663 977.803 534.382 977.436 533.94C977.07 533.498 976.891 532.925 976.948 532.351L1015.79 128.407C1015.91 127.214 1016.99 126.34 1018.16 126.452L1067.4 131.189C1071.39 131.575 1074.32 135.128 1073.94 139.122L1035.59 537.991C1035.47 539.109 1034.53 539.955 1033.43 539.955ZM981.478 530.613L1031.47 535.415L1069.62 138.709C1069.77 137.101 1068.6 135.673 1066.99 135.513L1019.91 130.983L981.487 530.603L981.478 530.613Z"
      fill="currentColor"
    />
    <path
      d="M1089.61 143.469L1035.3 138.25L996.475 542.193L1050.79 547.413L1089.61 143.469Z"
      fill="white"
    />
    <path
      d="M1050.77 549.617C1050.7 549.617 1050.63 549.617 1050.56 549.608L996.253 544.382C995.059 544.269 994.185 543.207 994.298 542.013L1033.14 138.069C1033.26 136.876 1034.33 136.002 1035.51 136.114L1089.82 141.34C1090.39 141.396 1090.92 141.678 1091.29 142.12C1091.65 142.562 1091.83 143.135 1091.77 143.709L1052.93 547.653C1052.87 548.226 1052.59 548.752 1052.15 549.119C1051.75 549.438 1051.27 549.617 1050.77 549.617ZM998.828 540.274L1048.82 545.077L1087.24 145.457L1037.25 140.654L998.828 540.274Z"
      fill="currentColor"
    />
    <path
      d="M1018.39 129.178L1035.3 138.286L1088.73 143.709L1075.07 135.945C1072.12 134.272 1068.84 133.267 1065.46 133.013L1018.39 129.178Z"
      fill="white"
    />
    <path
      d="M1088.73 145.88C1088.65 145.88 1088.58 145.88 1088.51 145.87L1035.08 140.447C1034.8 140.419 1034.52 140.334 1034.27 140.203L1017.36 131.095C1016.46 130.607 1016.02 129.554 1016.31 128.567C1016.6 127.58 1017.56 126.941 1018.57 127.016L1065.64 130.851C1069.3 131.124 1072.94 132.233 1076.15 134.056L1089.81 141.82C1090.7 142.327 1091.12 143.389 1090.81 144.367C1090.52 145.269 1089.68 145.88 1088.74 145.88H1088.73ZM1035.95 136.171L1078.71 140.513L1074 137.834C1071.35 136.331 1068.34 135.409 1065.3 135.184L1028.55 132.186L1035.95 136.171Z"
      fill="currentColor"
    />
    <path
      d="M1220.87 128.565L1270.11 133.297C1272.91 133.566 1274.96 136.058 1274.69 138.856L1236.36 537.728L1182.05 532.508L1220.87 128.565Z"
      fill="currentColor"
    />
    <path
      d="M1236.33 539.955C1236.26 539.955 1236.19 539.955 1236.12 539.946L1181.81 534.72C1181.23 534.663 1180.71 534.382 1180.34 533.94C1179.97 533.498 1179.79 532.925 1179.85 532.351L1218.7 128.407C1218.81 127.214 1219.86 126.34 1221.07 126.452L1270.31 131.189C1272.23 131.377 1273.98 132.299 1275.21 133.802C1276.44 135.297 1277.03 137.186 1276.84 139.122L1238.48 537.991C1238.43 538.564 1238.14 539.09 1237.7 539.457C1237.31 539.776 1236.83 539.955 1236.32 539.955H1236.33ZM1184.38 530.613L1234.37 535.415L1272.51 138.709C1272.59 137.928 1272.36 137.167 1271.86 136.566C1271.36 135.964 1270.66 135.588 1269.88 135.513L1222.8 130.983L1184.38 530.603V530.613Z"
      fill="currentColor"
    />
    <path
      d="M1292.51 143.454L1238.2 138.234L1199.38 542.178L1253.69 547.398L1292.51 143.454Z"
      fill="white"
    />
    <path
      d="M1253.67 549.617C1253.6 549.617 1253.53 549.617 1253.46 549.608L1199.15 544.382C1198.57 544.325 1198.05 544.043 1197.68 543.602C1197.31 543.16 1197.14 542.587 1197.19 542.013L1236.04 138.069C1236.15 136.876 1237.23 136.002 1238.41 136.114L1292.72 141.34C1293.3 141.396 1293.82 141.678 1294.19 142.12C1294.56 142.562 1294.73 143.135 1294.68 143.709L1255.83 547.653C1255.72 548.771 1254.78 549.617 1253.67 549.617ZM1201.72 540.274L1251.71 545.077L1290.14 145.457L1240.14 140.654L1201.72 540.274Z"
      fill="currentColor"
    />
    <path
      d="M1221.29 129.178L1238.2 138.286L1291.63 143.709L1277.98 135.945C1275.02 134.272 1271.74 133.267 1268.36 133.013L1221.29 129.178Z"
      fill="white"
    />
    <path
      d="M1291.63 145.88C1291.56 145.88 1291.48 145.88 1291.42 145.87L1237.98 140.447C1237.7 140.419 1237.42 140.334 1237.18 140.203L1220.27 131.095C1219.36 130.607 1218.92 129.554 1219.21 128.567C1219.51 127.58 1220.45 126.941 1221.47 127.016L1268.54 130.851C1272.21 131.124 1275.84 132.233 1279.05 134.056L1292.7 141.82C1293.6 142.327 1294.01 143.389 1293.7 144.367C1293.41 145.269 1292.57 145.88 1291.63 145.88ZM1238.85 136.171L1281.61 140.513L1276.9 137.834C1274.25 136.331 1271.25 135.409 1268.2 135.184L1231.45 132.186L1238.85 136.171Z"
      fill="currentColor"
    />
    <path
      d="M1269.92 172.264L1318.66 181.711C1321.32 182.228 1323.06 184.806 1322.55 187.473L1246.26 581.037L1192.7 570.654L1269.92 172.264Z"
      fill="currentColor"
    />
    <path
      d="M1246.2 583.284C1246.06 583.284 1245.93 583.274 1245.78 583.246L1192.22 572.86C1191.66 572.747 1191.16 572.419 1190.83 571.949C1190.51 571.469 1190.39 570.886 1190.5 570.323L1267.75 171.943C1267.85 171.379 1268.19 170.881 1268.66 170.561C1269.14 170.241 1269.72 170.119 1270.29 170.232L1319.03 179.687C1320.89 180.044 1322.5 181.107 1323.55 182.676C1324.61 184.246 1324.99 186.135 1324.63 187.986L1248.31 581.545C1248.12 582.579 1247.2 583.302 1246.19 583.302L1246.2 583.284ZM1195.17 569.007L1244.48 578.565L1320.38 187.141C1320.52 186.417 1320.37 185.693 1319.96 185.082C1319.55 184.471 1318.93 184.067 1318.21 183.926L1271.6 174.885L1195.18 569.007H1195.17Z"
      fill="currentColor"
    />
    <path
      d="M1262.54 592.391L1208.98 582.005L1286.23 183.625L1338.92 193.842C1339.4 193.936 1339.72 194.406 1339.62 194.885L1262.54 592.391Z"
      fill="white"
    />
    <path
      d="M1262.54 594.562C1262.4 594.562 1262.27 594.553 1262.13 594.525L1208.56 584.139C1208 584.026 1207.5 583.697 1207.17 583.218C1206.85 582.738 1206.73 582.156 1206.84 581.592L1284.09 183.212C1284.32 182.037 1285.47 181.266 1286.64 181.492L1339.33 191.708C1340.99 192.028 1342.08 193.635 1341.76 195.299L1264.68 592.805C1264.48 593.838 1263.57 594.562 1262.55 594.562H1262.54ZM1211.52 580.285L1260.82 589.844L1337.25 195.722L1287.94 186.163L1211.52 580.285Z"
      fill="currentColor"
    />
    <path
      d="M1269.72 173.183L1286.23 183.616L1338.9 194.124L1328.25 186.643C1324.03 183.673 1319.2 181.68 1314.11 180.806L1269.72 173.183Z"
      fill="white"
    />
    <path
      d="M1338.9 196.295C1338.76 196.295 1338.62 196.286 1338.48 196.258L1285.8 185.75C1285.54 185.693 1285.3 185.599 1285.07 185.458L1268.57 175.026C1267.69 174.471 1267.33 173.381 1267.7 172.413C1268.08 171.445 1269.1 170.881 1270.1 171.05L1314.48 178.672C1319.86 179.593 1325.05 181.736 1329.51 184.866L1340.16 192.348C1341 192.94 1341.31 194.04 1340.9 194.979C1340.55 195.788 1339.76 196.295 1338.91 196.295H1338.9ZM1287.05 181.567L1329.23 189.979L1326.99 188.41C1323.06 185.646 1318.47 183.757 1313.73 182.939L1280.07 177.159L1287.04 181.567H1287.05Z"
      fill="currentColor"
    />
    <path
      d="M1242.16 193.018L1188.04 186.142L1136.89 588.711L1191.02 595.587L1242.16 193.018Z"
      fill="currentColor"
    />
    <path
      d="M1190.98 597.805C1190.89 597.805 1190.8 597.805 1190.71 597.786L1136.58 590.906C1136.01 590.831 1135.49 590.54 1135.13 590.079C1134.78 589.628 1134.62 589.045 1134.7 588.472L1185.86 185.909C1185.93 185.336 1186.22 184.819 1186.68 184.471C1187.14 184.114 1187.72 183.964 1188.29 184.03L1242.42 190.91C1243.61 191.06 1244.45 192.15 1244.3 193.335L1193.14 595.897C1193.07 596.47 1192.77 596.987 1192.31 597.335C1191.93 597.636 1191.47 597.786 1190.99 597.786L1190.98 597.805ZM1139.28 586.874L1189.1 593.209L1239.71 194.951L1189.9 188.616L1139.29 586.874H1139.28Z"
      fill="currentColor"
    />
    <path
      d="M1208.02 605.822L1153.89 598.942L1205.05 196.38L1258.59 203.184C1258.92 203.222 1259.14 203.523 1259.1 203.852L1208.02 605.822Z"
      fill="white"
    />
    <path
      d="M1208.03 607.993C1207.94 607.993 1207.85 607.993 1207.76 607.974L1153.63 601.094C1153.06 601.019 1152.54 600.728 1152.18 600.267C1151.83 599.816 1151.67 599.233 1151.75 598.66L1202.91 196.098C1203.06 194.914 1204.16 194.068 1205.33 194.218L1258.87 201.023C1260.38 201.211 1261.45 202.602 1261.26 204.106L1210.18 606.085C1210.04 607.185 1209.11 607.984 1208.03 607.984V607.993ZM1156.33 597.062L1206.15 603.397L1256.76 205.139L1206.94 198.805L1156.33 597.062Z"
      fill="currentColor"
    />
    <path
      d="M1187.91 187.037L1205.06 196.38L1258.3 203.438L1240.48 192.573L1187.91 187.037Z"
      fill="white"
    />
    <path
      d="M1258.3 205.61C1258.21 205.61 1258.12 205.61 1258.02 205.591L1204.78 198.542C1204.51 198.504 1204.26 198.419 1204.03 198.297L1186.88 188.955C1185.97 188.457 1185.54 187.394 1185.85 186.408C1186.16 185.421 1187.13 184.791 1188.15 184.894L1240.73 190.43C1241.05 190.468 1241.36 190.571 1241.63 190.731L1259.44 201.587C1260.32 202.123 1260.7 203.194 1260.36 204.162C1260.05 205.036 1259.23 205.61 1258.31 205.61H1258.3ZM1205.75 194.284L1248.39 199.933L1239.78 194.688L1198.51 190.346L1205.75 194.293V194.284Z"
      fill="currentColor"
    />
    <path
      d="M949.107 200.004L894.991 193.052L843.287 595.55L897.403 602.501L949.107 200.004Z"
      fill="currentColor"
    />
    <path
      d="M897.461 604.619C897.367 604.619 897.273 604.619 897.179 604.6L843.061 597.654C842.487 597.579 841.97 597.288 841.622 596.827C841.275 596.376 841.115 595.793 841.19 595.22L892.865 192.724C892.941 192.15 893.232 191.633 893.692 191.286C894.144 190.938 894.717 190.778 895.3 190.853L949.419 197.799C950.612 197.949 951.449 199.04 951.298 200.224L899.623 602.72C899.482 603.811 898.552 604.619 897.471 604.619H897.461ZM845.767 593.622L895.582 600.014L946.702 201.822L896.888 195.43L845.767 593.622Z"
      fill="currentColor"
    />
    <path
      d="M966.127 210.229L912.011 203.277L860.307 605.775L914.423 612.726L966.127 210.229Z"
      fill="white"
    />
    <path
      d="M914.483 614.826C914.389 614.826 914.295 614.826 914.201 614.807L860.082 607.862C859.509 607.786 858.992 607.495 858.644 607.035C858.296 606.574 858.136 606.001 858.212 605.427L909.887 202.931C910.037 201.747 911.118 200.901 912.312 201.051L966.431 207.997C967.004 208.072 967.521 208.363 967.869 208.824C968.216 209.275 968.376 209.858 968.301 210.431L916.626 612.928C916.485 614.018 915.554 614.826 914.473 614.826H914.483ZM862.789 603.83L912.603 610.221L963.724 212.029L913.91 205.638L862.789 603.83Z"
      fill="currentColor"
    />
    <path
      d="M894.904 193.852L912.038 203.213L965.274 210.328L947.472 199.453L894.904 193.852Z"
      fill="white"
    />
    <path
      d="M965.274 212.499C965.18 212.499 965.077 212.499 964.983 212.48L911.747 205.356C911.484 205.318 911.23 205.234 910.995 205.111L893.861 195.75C892.949 195.252 892.517 194.18 892.827 193.194C893.137 192.207 894.087 191.568 895.13 191.68L947.698 197.282C948.018 197.32 948.328 197.423 948.6 197.592L966.402 208.476C967.276 209.012 967.661 210.083 967.314 211.051C967.003 211.926 966.176 212.499 965.274 212.499ZM912.725 201.107L955.358 206.813L946.758 201.559L905.488 197.16L912.725 201.117V201.107Z"
      fill="currentColor"
    />
    <path
      d="M602.789 243.958L649.96 228.941C652.62 228.094 655.466 229.565 656.312 232.225L777.887 614.087L725.898 630.639L602.789 243.958Z"
      fill="currentColor"
    />
    <path
      d="M725.875 632.862C724.954 632.862 724.099 632.27 723.807 631.349L600.729 244.661C600.362 243.515 601.001 242.302 602.138 241.936L649.311 226.916C653.109 225.704 657.178 227.809 658.391 231.606L779.937 613.482C780.107 614.027 780.06 614.629 779.796 615.136C779.533 615.644 779.073 616.029 778.528 616.208L726.533 632.759C726.317 632.825 726.091 632.862 725.875 632.862ZM605.522 245.413L727.285 627.966L775.144 612.73L654.255 232.932C653.776 231.419 652.141 230.573 650.637 231.061L605.531 245.423L605.522 245.413Z"
      fill="currentColor"
    />
    <path
      d="M674.526 229.477L622.536 246.029L745.644 632.71L797.634 616.158L674.526 229.477Z"
      fill="white"
    />
    <path
      d="M745.622 634.93C744.701 634.93 743.846 634.338 743.554 633.417L620.476 246.729C620.109 245.583 620.748 244.37 621.885 244.004L673.88 227.452C675.027 227.086 676.249 227.715 676.606 228.862L799.694 615.55C799.863 616.095 799.816 616.696 799.553 617.204C799.29 617.711 798.829 618.097 798.284 618.275L746.289 634.827C746.073 634.893 745.848 634.93 745.631 634.93H745.622ZM625.269 247.481L747.032 630.033L794.891 614.798L673.119 232.246L625.26 247.481H625.269Z"
      fill="currentColor"
    />
    <path
      d="M602.787 244.004L621.321 245.949C622.12 246.034 622.929 245.949 623.699 245.714L673.795 230.056L657.141 228.167C654.453 227.866 651.736 228.167 649.18 229.031L602.777 244.004H602.787Z"
      fill="white"
    />
    <path
      d="M621.941 248.149C621.659 248.149 621.377 248.13 621.096 248.102L602.561 246.156C601.546 246.053 600.738 245.244 600.634 244.22C600.521 243.195 601.151 242.246 602.129 241.927L648.531 226.954C651.341 225.995 654.424 225.657 657.403 225.995L674.058 227.885C675.073 227.997 675.882 228.815 675.976 229.84C676.07 230.864 675.44 231.813 674.463 232.114L624.366 247.773C623.586 248.017 622.778 248.139 621.96 248.139L621.941 248.149ZM613.229 242.914L621.547 243.788C622.064 243.844 622.562 243.788 623.05 243.637L663.325 231.052L656.905 230.328C654.546 230.065 652.131 230.328 649.884 231.09L613.229 242.914Z"
      fill="currentColor"
    />
    <path
      d="M753.873 223.522L700.301 233.857L777.176 632.314L830.749 621.979L753.873 223.522Z"
      fill="currentColor"
    />
    <path
      d="M777.174 634.489C776.741 634.489 776.318 634.357 775.961 634.113C775.482 633.793 775.153 633.295 775.05 632.722L698.176 234.267C698.063 233.703 698.185 233.12 698.505 232.641C698.824 232.161 699.323 231.832 699.896 231.729L753.47 221.39C754.034 221.287 754.616 221.4 755.096 221.728C755.575 222.048 755.904 222.546 756.007 223.119L832.881 621.575C833.107 622.749 832.336 623.887 831.161 624.122L777.587 634.46C777.456 634.489 777.315 634.498 777.174 634.498V634.489ZM702.838 235.582L778.894 629.77L828.21 620.259L752.163 226.071L702.847 235.582H702.838Z"
      fill="currentColor"
    />
    <path
      d="M773.233 227.895L719.66 238.231L796.535 636.688L850.108 626.352L773.233 227.895Z"
      fill="white"
    />
    <path
      d="M796.545 638.859C796.112 638.859 795.69 638.728 795.332 638.483C794.853 638.164 794.524 637.665 794.421 637.092L717.547 238.637C717.434 238.073 717.556 237.49 717.876 237.011C718.196 236.532 718.694 236.203 719.267 236.099L772.841 225.761C773.405 225.657 773.987 225.77 774.467 226.09C774.946 226.409 775.275 226.907 775.378 227.481L852.252 625.936C852.478 627.111 851.707 628.248 850.532 628.483L796.958 638.822C796.827 638.85 796.686 638.859 796.545 638.859ZM722.209 239.953L798.265 634.141L847.581 624.629L771.534 230.441L722.218 239.953H722.209Z"
      fill="currentColor"
    />
    <path
      d="M700.46 234.708L719.662 238.242L772.456 228.345L757.173 224.764C753.865 223.984 750.415 223.956 747.088 224.67L700.46 234.708Z"
      fill="white"
    />
    <path
      d="M719.662 240.404C719.53 240.404 719.398 240.395 719.267 240.366L700.065 236.832C699.05 236.644 698.307 235.761 698.288 234.727C698.27 233.693 698.993 232.791 699.999 232.575L746.627 222.537C750.246 221.757 754.062 221.794 757.661 222.64L772.944 226.221C773.94 226.456 774.645 227.358 774.617 228.383C774.598 229.407 773.856 230.281 772.85 230.469L720.056 240.366C719.925 240.395 719.793 240.404 719.652 240.404H719.662ZM711.55 234.539L719.662 236.034L761.938 228.11L756.684 226.879C753.704 226.184 750.546 226.146 747.557 226.794L711.56 234.549L711.55 234.539Z"
      fill="currentColor"
    />
    <path
      d="M1086.64 168.663H1136.86C1139.26 168.663 1141.2 170.608 1141.2 173.005V574.468H1086.64V168.663Z"
      fill="currentColor"
    />
    <path
      d="M1141.2 576.639H1086.64C1085.44 576.639 1084.47 575.671 1084.47 574.468V168.663C1084.47 167.46 1085.44 166.491 1086.64 166.491H1136.86C1140.45 166.491 1143.37 169.415 1143.37 173.005V574.468C1143.37 575.671 1142.41 576.639 1141.2 576.639ZM1088.81 572.296H1139.03V173.005C1139.03 171.811 1138.05 170.834 1136.86 170.834H1088.81V572.296Z"
      fill="currentColor"
    />
    <path
      d="M1104.83 176.624H1158.62C1159.04 176.624 1159.39 176.971 1159.39 177.394V582.429H1104.83V176.624Z"
      fill="white"
    />
    <path
      d="M1159.39 584.6H1104.83C1103.63 584.6 1102.66 583.632 1102.66 582.429V176.624C1102.66 175.42 1103.63 174.452 1104.83 174.452H1158.62C1160.25 174.452 1161.56 175.768 1161.56 177.394V582.429C1161.56 583.632 1160.59 584.6 1159.39 584.6ZM1107 580.257H1157.22V178.795H1107V580.257Z"
      fill="currentColor"
    />
    <path
      d="M1086.64 169.527L1104.83 176.633L1158.53 176.915L1143.57 170.213C1140.88 169.01 1137.97 168.418 1135.03 168.484L1086.63 169.527H1086.64Z"
      fill="white"
    />
    <path
      d="M1158.53 179.086H1158.53L1104.81 178.804C1104.55 178.804 1104.27 178.748 1104.03 178.654L1085.84 171.548C1084.88 171.172 1084.31 170.166 1084.5 169.151C1084.68 168.136 1085.55 167.384 1086.59 167.356L1134.98 166.313C1138.24 166.247 1141.49 166.905 1144.46 168.23L1159.42 174.932C1160.36 175.355 1160.87 176.37 1160.66 177.375C1160.44 178.372 1159.56 179.086 1158.53 179.086ZM1105.24 174.462L1148.25 174.687L1142.69 172.197C1140.31 171.134 1137.68 170.599 1135.07 170.655L1097.56 171.463L1105.24 174.462Z"
      fill="currentColor"
    />
    <path
      d="M1079.82 236.87H1025.26V642.675H1079.82V236.87Z"
      fill="currentColor"
    />
    <path
      d="M1079.82 644.846H1025.26C1024.06 644.846 1023.09 643.878 1023.09 642.675V236.87C1023.09 235.667 1024.06 234.699 1025.26 234.699H1079.82C1081.02 234.699 1081.99 235.667 1081.99 236.87V642.675C1081.99 643.878 1081.02 644.846 1079.82 644.846ZM1027.43 640.504H1077.65V239.041H1027.43V640.504Z"
      fill="currentColor"
    />
    <path d="M1098 244.831H1043.44V650.636H1098V244.831Z" fill="white" />
    <path
      d="M1098.01 652.807H1043.44C1042.24 652.807 1041.27 651.839 1041.27 650.636V244.831C1041.27 243.628 1042.24 242.66 1043.44 242.66H1098.01C1099.21 242.66 1100.18 243.628 1100.18 244.831V650.636C1100.18 651.839 1099.21 652.807 1098.01 652.807ZM1045.62 648.465H1095.83V247.002H1045.62V648.465Z"
      fill="currentColor"
    />
    <path
      d="M1025.26 237.725L1043.44 244.831L1097.15 245.113L1078.12 236.588L1025.26 237.725Z"
      fill="white"
    />
    <path
      d="M1097.15 247.284H1097.14L1043.43 247.002C1043.16 247.002 1042.89 246.946 1042.65 246.852L1024.46 239.746C1023.5 239.37 1022.93 238.365 1023.11 237.349C1023.29 236.334 1024.17 235.582 1025.2 235.554L1078.06 234.417C1078.4 234.417 1078.7 234.473 1078.99 234.605L1098.03 243.13C1098.97 243.553 1099.49 244.568 1099.27 245.573C1099.06 246.57 1098.17 247.284 1097.15 247.284ZM1043.86 242.66L1086.87 242.885L1077.67 238.769L1036.17 239.662L1043.85 242.66H1043.86Z"
      fill="currentColor"
    />
    <path
      d="M925.77 670.975L873.334 686.06L761.148 296.074L808.688 282.398C811.395 281.618 814.215 283.178 814.995 285.885L925.77 670.975Z"
      fill="currentColor"
    />
    <path
      d="M873.343 688.231C872.403 688.231 871.529 687.611 871.257 686.662L759.062 296.675C758.902 296.12 758.968 295.528 759.25 295.021C759.532 294.513 759.992 294.147 760.547 293.987L808.087 280.312C811.931 279.202 815.972 281.439 817.081 285.284L927.857 670.373C928.186 671.53 927.528 672.733 926.372 673.062L873.935 688.147C873.738 688.203 873.531 688.231 873.334 688.231H873.343ZM763.836 297.558L874.828 683.372L923.092 669.49L812.908 286.487C812.457 284.936 810.84 284.033 809.29 284.485L763.836 297.558Z"
      fill="currentColor"
    />
    <path
      d="M833.269 283.599L780.835 298.683L893.031 688.67L945.465 673.586L833.269 283.599Z"
      fill="white"
    />
    <path
      d="M893.015 690.853C892.075 690.853 891.201 690.233 890.929 689.284L778.744 299.297C778.584 298.743 778.649 298.15 778.931 297.643C779.213 297.135 779.674 296.769 780.229 296.609L832.665 281.524C833.821 281.195 835.024 281.862 835.353 283.009L947.538 672.996C947.698 673.55 947.632 674.142 947.35 674.65C947.069 675.157 946.608 675.524 946.053 675.684L893.617 690.769C893.42 690.825 893.213 690.853 893.015 690.853ZM783.518 300.181L894.51 685.994L942.773 672.112L831.782 286.299L783.518 300.181Z"
      fill="currentColor"
    />
    <path
      d="M761.76 296.431L780.83 298.696L832.524 284.118L816.969 281.909C813.614 281.43 810.192 281.712 806.95 282.718L761.76 296.431Z"
      fill="white"
    />
    <path
      d="M780.83 300.867C780.746 300.867 780.661 300.867 780.576 300.848L761.506 298.583C760.491 298.461 759.692 297.643 759.598 296.619C759.504 295.594 760.143 294.645 761.13 294.353L806.32 280.64C809.826 279.55 813.623 279.24 817.27 279.757L832.825 281.966C833.84 282.107 834.62 282.943 834.686 283.968C834.752 284.992 834.094 285.923 833.107 286.205L781.413 300.782C781.216 300.839 781.018 300.867 780.821 300.867H780.83ZM772.315 295.5L780.661 296.487L822.035 284.823L816.668 284.062C813.651 283.629 810.512 283.883 807.598 284.795L772.315 295.5Z"
      fill="currentColor"
    />
    <path
      d="M1024.12 275.034L970.003 268.083L918.299 670.58L972.415 677.532L1024.12 275.034Z"
      fill="currentColor"
    />
    <path
      d="M972.483 679.641C972.389 679.641 972.295 679.641 972.201 679.622L918.082 672.676C917.509 672.601 916.992 672.31 916.644 671.849C916.296 671.398 916.136 670.815 916.212 670.242L967.887 267.745C968.037 266.561 969.118 265.715 970.321 265.865L1024.44 272.811C1025.63 272.962 1026.47 274.052 1026.32 275.236L974.645 677.733C974.504 678.823 973.573 679.631 972.492 679.631L972.483 679.641ZM920.789 668.644L970.603 675.035L1021.72 276.843L971.91 270.452L920.789 668.644Z"
      fill="currentColor"
    />
    <path
      d="M1041.16 285.251L987.041 278.3L935.337 680.797L989.453 687.749L1041.16 285.251Z"
      fill="white"
    />
    <path
      d="M989.504 689.848C989.41 689.848 989.316 689.848 989.222 689.829L935.103 682.883C934.53 682.808 934.013 682.517 933.665 682.056C933.318 681.596 933.158 681.022 933.233 680.449L984.908 277.953C985.059 276.768 986.149 275.922 987.333 276.073L1041.45 283.018C1042.03 283.094 1042.54 283.385 1042.89 283.846C1043.24 284.297 1043.4 284.879 1043.32 285.453L991.647 687.949C991.506 689.049 990.576 689.848 989.495 689.848H989.504ZM937.81 678.851L987.625 685.242L1038.75 287.051L988.931 280.659L937.81 678.851Z"
      fill="currentColor"
    />
    <path
      d="M969.926 268.873L987.06 278.234L1040.3 285.359L1022.49 274.475L969.926 268.873Z"
      fill="white"
    />
    <path
      d="M1040.3 287.53C1040.2 287.53 1040.1 287.53 1040 287.511L986.769 280.387C986.506 280.349 986.252 280.264 986.017 280.142L968.883 270.781C967.971 270.283 967.539 269.211 967.849 268.225C968.159 267.238 969.089 266.608 970.151 266.711L1022.72 272.313C1023.04 272.351 1023.35 272.454 1023.62 272.623L1041.42 283.507C1042.3 284.043 1042.68 285.114 1042.34 286.082C1042.02 286.957 1041.2 287.53 1040.3 287.53ZM987.746 276.138L1030.38 281.844L1021.78 276.59L980.509 272.191L987.746 276.148V276.138Z"
      fill="currentColor"
    />
    <path
      d="M1288.89 528.94L1308.31 405.251L1248.9 381.096C1242.05 378.436 1234.16 378.746 1228.14 382.684C1053.32 497.069 879.086 508.611 705.658 378.379C703.693 376.904 700.967 376.368 698.599 377.251L624.019 407.337L653.653 526.177L1288.91 528.94H1288.89Z"
      fill="currentColor"
    />
    <path
      d="M1268.97 1133.06L751.017 1135.87C713.393 1136.08 681.926 1107.35 678.711 1069.86L624.01 431.812L697.575 413.616C700.63 412.854 703.919 413.494 706.401 415.27C880.243 539.899 1054.9 527.887 1230.14 417.328C1234.97 414.274 1241.14 413.447 1246.72 415.204L1306.04 433.851L1268.98 1133.06H1268.97Z"
      fill="white"
    />
    <path
      d="M750.622 1138.05C712.312 1138.05 679.82 1108.26 676.54 1070.05L621.829 432C621.735 430.938 622.43 429.96 623.474 429.706L697.039 411.51C700.676 410.608 704.643 411.36 707.65 413.512C872.836 531.938 1043.36 532.586 1228.96 415.495C1234.36 412.093 1241.24 411.209 1247.35 413.136L1306.67 431.784C1307.62 432.084 1308.24 432.977 1308.18 433.973L1271.12 1133.18C1271.06 1134.33 1270.12 1135.23 1268.97 1135.24L751.017 1138.05C750.885 1138.05 750.744 1138.05 750.613 1138.05H750.622ZM626.321 433.475L680.873 1069.68C683.956 1105.65 714.558 1133.71 750.631 1133.71C750.763 1133.71 750.885 1133.71 751.007 1133.71L1266.91 1130.91L1303.77 435.421L1246.06 417.281C1241.14 415.73 1235.62 416.435 1231.28 419.17C1141.17 476.024 1052.51 505.461 967.765 506.674C965.951 506.702 964.118 506.711 962.304 506.711C874.631 506.711 788.142 476.56 705.122 417.046C703.148 415.627 700.516 415.138 698.082 415.73L626.312 433.475H626.321Z"
      fill="currentColor"
    />
    <path
      d="M690.403 1072.03C689.285 1072.03 688.345 1071.18 688.242 1070.05L633.531 432C633.427 430.806 634.311 429.753 635.504 429.65C636.689 429.547 637.751 430.43 637.854 431.624L692.565 1069.67C692.669 1070.87 691.785 1071.92 690.591 1072.02C690.526 1072.02 690.469 1072.02 690.403 1072.02V1072.03Z"
      fill="currentColor"
    />
    <path
      d="M1232.32 946.549L1258.01 958.439L1489.29 458.767L1473.5 451.464C1468.03 448.927 1461.54 451.314 1459.01 456.784L1232.32 946.549Z"
      fill="currentColor"
    />
    <path
      d="M1463.72 448.776L1455.64 466.174L1591.33 591.226L1609.74 571.254L1476.26 446.342C1472.34 442.677 1465.98 443.908 1463.73 448.776H1463.72Z"
      fill="currentColor"
    />
    <path
      d="M1035.15 782.896L1513.39 782.896V719.971H1035.15V782.896Z"
      fill="white"
    />
    <path
      d="M1513.4 785.059H1035.16C1033.96 785.059 1032.99 784.09 1032.99 782.887V719.962C1032.99 718.759 1033.96 717.791 1035.16 717.791H1513.4C1514.6 717.791 1515.57 718.759 1515.57 719.962V782.887C1515.57 784.09 1514.6 785.059 1513.4 785.059ZM1037.34 780.716H1511.23V722.133H1037.34V780.716Z"
      fill="currentColor"
    />
    <path
      d="M1139.05 1521.82L1415.6 1519.49C1440.53 1519.29 1461.02 1499.77 1462.44 1474.89L1500.81 801.769H1028.87L1081.3 1468.88C1083.66 1498.95 1108.89 1522.07 1139.04 1521.82H1139.05Z"
      fill="white"
    />
    <path
      d="M1138.57 1524C1107.63 1524 1081.58 1499.96 1079.15 1469.06L1026.71 801.948C1026.67 801.346 1026.87 800.745 1027.29 800.303C1027.7 799.861 1028.27 799.607 1028.89 799.607H1500.83C1501.43 799.607 1502 799.852 1502.41 800.284C1502.83 800.716 1503.03 801.299 1503 801.901L1464.63 1475.02C1463.15 1500.96 1441.63 1521.46 1415.65 1521.67L1139.1 1524C1138.93 1524 1138.76 1524 1138.6 1524H1138.57ZM1139.03 1519.65L1415.58 1517.33C1439.29 1517.13 1458.91 1498.44 1460.27 1474.77L1498.51 803.95H1031.22L1083.47 1468.72C1085.73 1497.52 1110.11 1519.89 1139.02 1519.65H1139.03Z"
      fill="currentColor"
    />
    <path
      d="M1003.47 826.949L1529.37 826.949C1537.93 826.949 1544.87 820.01 1544.87 811.45V779.522C1544.87 770.962 1537.93 764.023 1529.37 764.023L1003.47 764.023C994.912 764.023 987.973 770.962 987.973 779.522V811.45C987.973 820.01 994.912 826.949 1003.47 826.949Z"
      fill="currentColor"
    />
    <path
      d="M1117.03 1307.83C1116.33 1342.67 884.594 1374.24 612.26 1374.24C339.927 1374.24 122.154 1345.88 119.156 1311.15C112.163 1230.35 339.927 1248.06 612.26 1248.06C884.594 1248.06 1119.36 1190.5 1117.03 1307.84V1307.83Z"
      fill="white"
    />
    <path
      d="M612.261 1376.42C371.573 1376.42 120.519 1352.07 116.994 1311.33C116.026 1300.17 119.363 1290.39 126.901 1282.25C164.609 1241.57 306.599 1243.08 486.372 1244.99C526.956 1245.42 568.922 1245.87 612.261 1245.87C686.023 1245.87 757.915 1241.59 821.349 1237.81C955.687 1229.81 1061.79 1223.51 1101.78 1261.95C1113.89 1273.58 1119.59 1288.6 1119.2 1307.86C1118.38 1348.86 856.425 1376.41 612.261 1376.41V1376.42ZM380.653 1248.57C253.617 1248.57 158.942 1254.08 130.087 1285.21C123.32 1292.51 120.453 1300.93 121.318 1310.96C123.874 1340.47 321.759 1372.07 612.261 1372.07C902.762 1372.07 1114.26 1338.13 1114.86 1307.79C1115.21 1289.8 1109.95 1275.83 1098.78 1265.09C1060.15 1227.96 954.879 1234.22 821.612 1242.15C758.122 1245.93 686.155 1250.22 612.27 1250.22C568.903 1250.22 526.928 1249.78 486.334 1249.34C449.302 1248.95 413.868 1248.57 380.662 1248.57H380.653Z"
      fill="currentColor"
    />
    <path
      d="M1096.04 1283.04C749.785 1297.83 424.808 1298.91 126.149 1283.04C110.763 1283.04 98.168 1272.68 98.168 1260.01V1231.22C98.168 1218.56 110.763 1208.19 126.149 1208.19C448.409 1234.84 771.788 1232.71 1096.04 1208.19C1111.43 1208.19 1124.02 1218.55 1124.02 1231.22V1260.01C1124.02 1272.67 1111.44 1283.04 1096.04 1283.04Z"
      fill="currentColor"
    />
    <path
      d="M142.596 1160L114.287 1194.16C108.995 1200.54 113.394 1209.45 122.426 1210.68C346.797 1225.25 525.367 1233.34 645.09 1261.45C683.325 1270.42 722.565 1275.39 762.05 1275.68C788.358 1275.87 813.782 1275.73 821.386 1274.62C918.354 1260.53 1000.97 1241.68 1107.16 1204.03C1123.06 1200.14 1124.12 1181.22 1108.73 1176.04L1013.42 1143.99L142.596 1160Z"
      fill="white"
    />
    <path
      d="M780.172 1277.92C774.645 1277.92 768.592 1277.89 762.041 1277.84C723.289 1277.56 683.767 1272.76 644.602 1263.55C540.875 1239.19 394.328 1229.99 208.793 1218.33C180.775 1216.57 151.93 1214.76 122.286 1212.83C116.787 1212.1 112.379 1208.85 110.622 1204.36C109.071 1200.39 109.794 1196.16 112.614 1192.76L140.924 1158.6C141.328 1158.11 141.92 1157.83 142.55 1157.81L1013.38 1141.8C1013.64 1141.8 1013.88 1141.84 1014.11 1141.91L1109.42 1173.96C1117.28 1176.61 1122.16 1183.06 1121.85 1190.4C1121.53 1197.88 1116.01 1204.03 1107.78 1206.09C1009.03 1241.1 926.127 1261.57 821.696 1276.74C816.423 1277.51 802.485 1277.9 780.172 1277.9V1277.92ZM143.631 1162.15L115.951 1195.54C114.174 1197.69 113.704 1200.33 114.663 1202.79C115.847 1205.82 118.93 1208.01 122.709 1208.52C152.202 1210.43 181.038 1212.25 209.056 1214C394.788 1225.68 541.496 1234.89 645.579 1259.33C684.434 1268.46 723.628 1273.23 762.06 1273.51C794.392 1273.74 814.797 1273.39 821.066 1272.47C925.244 1257.34 1007.92 1236.92 1106.43 1201.99C1106.49 1201.96 1106.57 1201.94 1106.63 1201.93C1113.01 1200.37 1117.28 1195.78 1117.51 1190.24C1117.69 1185.98 1115.29 1180.54 1108.02 1178.1L1013.08 1146.17L143.621 1162.15H143.631Z"
      fill="currentColor"
    />
    <path
      d="M1076.24 1174.89C782.07 1192.87 472.508 1192.57 148.311 1174.89C123.31 1174.89 102.849 1145.53 102.849 1109.67C102.849 1073.8 123.31 1044.45 148.311 1044.45C450.016 1027.06 758.939 1026.16 1076.24 1044.45C1101.24 1044.45 1121.7 1073.8 1121.7 1109.67C1121.7 1145.53 1101.24 1174.89 1076.24 1174.89Z"
      fill="currentColor"
    />
    <path
      d="M67.6129 1077.53C66.4944 1079.16 65.376 1080.8 64.2763 1082.48C36.0608 1125.2 99.6256 1118.86 120.595 1112.24C151.526 1102.49 175.747 1083.62 208.784 1078.88C244.35 1073.77 280.667 1078.02 316.261 1080.55C426.059 1088.35 547.408 1080.96 652.366 1045.91C663.541 1042.17 674.848 1037.86 683.74 1030.08C753.771 968.749 575.116 967.809 550.313 968.035C479.106 968.693 407.891 969.36 336.685 970.018C230.411 971.005 132.86 983.271 67.6129 1077.53Z"
      fill="white"
    />
    <path
      d="M87.1622 1119.13C74.9718 1119.13 63.2419 1116.88 57.9127 1109.81C52.8561 1103.11 54.3882 1093.5 62.4712 1081.27C63.5803 1079.59 64.7082 1077.93 65.836 1076.29C133.931 977.904 236.971 968.768 336.666 967.838L550.293 965.855C573.274 965.648 688.579 966.221 700.967 998.713C704.595 1008.23 699.285 1019.33 685.177 1031.69C675.759 1039.95 663.663 1044.41 653.061 1047.94C533.319 1087.94 403.736 1088.91 316.11 1082.69C310.706 1082.31 305.273 1081.88 299.841 1081.46C269.933 1079.12 239.02 1076.71 209.094 1081.01C189.648 1083.8 173.576 1091.44 156.564 1099.53C145.379 1104.84 133.818 1110.33 121.243 1114.3C114.72 1116.36 100.659 1119.12 87.1622 1119.12V1119.13ZM555.82 970.178C553.94 970.178 552.117 970.178 550.331 970.206L336.703 972.189C238.146 973.101 136.318 982.087 69.3982 1078.77C68.2797 1080.38 67.1801 1082.02 66.0804 1083.68C59.1252 1094.22 57.5368 1102.13 61.3715 1107.2C69.9527 1118.58 104.052 1115.18 119.936 1110.17C132.221 1106.3 143.65 1100.87 154.693 1095.63C171.254 1087.76 188.379 1079.62 208.464 1076.73C238.87 1072.36 270.027 1074.8 300.16 1077.16C305.583 1077.58 311.007 1078 316.402 1078.39C403.623 1084.58 532.595 1083.62 651.67 1043.86C661.868 1040.45 673.485 1036.17 682.301 1028.45C694.905 1017.41 699.821 1007.93 696.898 1000.28C687.809 976.447 606.452 970.187 555.811 970.187L555.82 970.178Z"
      fill="currentColor"
    />
    <path
      d="M671.679 1006.65C671.943 1007.01 672.178 1007.37 672.403 1007.75C680.139 1020.53 685.214 1035.29 694.462 1046.81C707.743 1063.34 727.979 1071.08 747.801 1074.57C800.783 1083.91 853.746 1083.69 907.178 1080.47C980.64 1076.07 1053.27 1107.56 1126.6 1100.94C1163.16 1097.65 1161.74 1060.28 1136.56 1038.92C1123.83 1028.12 1108.02 1022.87 1092.58 1017.94C1023.65 995.987 954.032 976.531 883.709 960.675C814.891 945.167 743.177 928.522 672.657 937.874C657.703 939.857 639.234 946.944 626.264 955.092C588.687 978.712 657.083 986.231 671.67 1006.64L671.679 1006.65Z"
      fill="white"
    />
    <path
      d="M1105.71 1104.03C1074.65 1104.03 1043.59 1098.49 1013.35 1093.11C978.62 1086.92 942.707 1080.52 907.32 1082.65C863.483 1085.28 805.699 1086.98 747.435 1076.71C722.904 1072.39 704.51 1062.79 692.78 1048.17C686.962 1040.92 682.733 1032.38 678.644 1024.12C676.116 1019.01 673.503 1013.73 670.561 1008.87C670.364 1008.54 670.157 1008.23 669.932 1007.92C664.903 1000.89 652.675 995.32 640.842 989.925C626.217 983.252 613.585 977.49 613.002 968.777C612.664 963.693 616.63 958.627 625.127 953.279C638.718 944.735 657.704 937.686 672.385 935.74C742.002 926.511 812.372 942.385 880.429 957.734L884.208 958.589C951.184 973.684 1019.56 992.435 1093.26 1015.9C1107.18 1020.34 1124.5 1025.86 1137.98 1037.3C1152.94 1049.99 1160.22 1068.9 1155.66 1083.29C1152.03 1094.73 1141.78 1101.78 1126.8 1103.13C1119.78 1103.76 1112.75 1104.06 1105.72 1104.06L1105.71 1104.03ZM921.578 1077.89C952.688 1077.89 983.846 1083.44 1014.11 1088.83C1050.91 1095.39 1088.94 1102.17 1126.41 1098.78C1139.53 1097.6 1148.45 1091.62 1151.51 1081.95C1155.55 1069.19 1148.83 1052.17 1135.17 1040.58C1123.29 1030.5 1109.17 1025.5 1091.93 1020.01C1018.36 996.579 950.094 977.866 883.24 962.8L879.461 961.944C811.78 946.681 741.796 930.9 672.949 940.026C658.831 941.897 640.541 948.692 627.43 956.935C620.503 961.287 617.1 965.168 617.326 968.467C617.739 974.595 630.39 980.366 642.637 985.949C654.987 991.579 667.751 997.397 673.447 1005.37C673.747 1005.78 674.02 1006.19 674.274 1006.62C677.31 1011.64 679.97 1016.99 682.535 1022.18C686.521 1030.24 690.647 1038.56 696.164 1045.44C707.226 1059.2 724.727 1068.28 748.187 1072.42C806 1082.61 863.455 1080.92 907.057 1078.29C911.888 1078 916.737 1077.87 921.578 1077.87V1077.89Z"
      fill="currentColor"
    />
    <path
      d="M113.583 1300.67C433.362 1359.28 768.546 1361.53 1120.73 1300.67C1120.73 1455.89 988.217 1581.73 824.761 1581.73H409.564C246.098 1581.73 113.592 1455.9 113.592 1300.67H113.583Z"
      fill="white"
    />
    <path
      d="M824.76 1583.9H409.564C245.167 1583.9 111.421 1456.85 111.421 1300.67C111.421 1300.02 111.703 1299.41 112.201 1299.01C112.699 1298.59 113.338 1298.42 113.987 1298.54C438.024 1357.93 776.619 1357.93 1120.37 1298.54C1121 1298.42 1121.65 1298.6 1122.14 1299.02C1122.63 1299.43 1122.91 1300.04 1122.91 1300.68C1122.91 1456.85 989.157 1583.91 824.77 1583.91L824.76 1583.9ZM115.773 1303.27C117.258 1455.85 248.476 1579.56 409.564 1579.56H824.76C985.858 1579.56 1117.09 1455.84 1118.55 1303.25C947.209 1332.72 776.018 1347.65 609.648 1347.65C608.087 1347.65 606.565 1347.65 605.005 1347.65C440.354 1347.38 275.77 1332.46 115.773 1303.29V1303.27Z"
      fill="currentColor"
    />
    <path
      d="M620.748 1413.6C472.818 1413.6 315.941 1399.22 142.108 1369.75C140.924 1369.55 140.135 1368.43 140.332 1367.25C140.529 1366.07 141.657 1365.27 142.832 1365.48C492.669 1424.76 763.987 1424.13 1079.31 1363.27C1080.49 1363.04 1081.62 1363.81 1081.85 1364.99C1082.07 1366.16 1081.3 1367.3 1080.13 1367.53C925.78 1397.33 787.635 1411.92 645.373 1413.47C637.196 1413.56 628.981 1413.6 620.738 1413.6H620.748Z"
      fill="currentColor"
    />
    <path
      d="M1117.23 999.389C778.301 1026.88 442.581 1026.89 110.076 999.389C110.076 844.167 279.764 718.909 443.23 718.909L781.29 716.69C944.756 716.69 1117.23 844.158 1117.23 999.389Z"
      fill="white"
    />
    <path
      d="M611.179 1022.09C442.845 1022.09 275.403 1015.25 109.898 1001.55C108.77 1001.46 107.905 1000.52 107.905 999.39C107.905 925.524 145.576 854.45 213.991 799.25C278.975 746.814 362.531 716.738 443.23 716.738L781.281 714.52C861.989 714.52 946.053 745 1011.92 798.141C1081.23 854.055 1119.4 925.524 1119.4 999.39C1119.4 1000.52 1118.53 1001.46 1117.4 1001.55C948.647 1015.24 779.467 1022.09 611.179 1022.09ZM112.257 997.397C441.707 1024.54 779.053 1024.54 1115.05 997.397C1114.36 925.58 1076.83 856.094 1009.19 801.534C944.079 749.004 861.021 718.881 781.29 718.881L443.239 721.099C287.641 721.099 113.714 839.13 112.257 997.416V997.397Z"
      fill="currentColor"
    />
    <path
      d="M586.497 953.523C445.965 953.523 302.274 947.621 156.29 935.825C155.097 935.731 154.204 934.678 154.298 933.485C154.392 932.291 155.435 931.398 156.638 931.492C465.637 956.474 764.371 954.98 1044.53 927.065C1045.76 926.943 1046.79 927.817 1046.91 929.011C1047.03 930.204 1046.16 931.266 1044.97 931.389C896.915 946.136 743.675 953.523 586.488 953.523H586.497Z"
      fill="currentColor"
    />
    <path
      d="M430.57 809.918C426.979 809.401 423.38 811.281 421.011 813.903C418.652 816.525 417.299 819.805 415.983 823.029C415.334 824.618 414.705 826.46 415.588 827.935C416.199 828.95 417.374 829.514 418.483 830.013C423.699 832.353 429.235 834.609 434.997 834.298C440.758 833.988 446.727 830.285 447.45 824.843C448.437 817.399 440.044 812.22 432.769 809.297L430.56 809.908L430.57 809.918Z"
      fill="white"
    />
    <path
      d="M680.336 766.571C686.182 768.121 691.934 770.668 697.987 770.34C704.04 770.011 710.459 765.274 709.463 759.597C708.514 754.211 702.047 751.692 696.53 750.141C692.037 748.882 687.545 747.622 683.052 746.354C674.273 743.891 651.171 739.53 649.677 754.23C648.351 767.275 671.106 764.117 680.336 766.571Z"
      fill="currentColor"
    />
    <path
      d="M931.588 813.132C925.667 814.354 919.388 814.655 914.106 817.653C908.833 820.651 905.205 827.757 908.645 832.39C911.907 836.789 918.815 836.15 924.445 835.069C929.022 834.195 933.609 833.312 938.186 832.437C947.134 830.717 969.757 824.298 964.522 810.482C959.879 798.216 940.931 811.215 931.579 813.142L931.588 813.132Z"
      fill="currentColor"
    />
    <path
      d="M569.232 785.659C566.769 789.259 564.269 792.953 563.273 797.136C562.277 801.318 563.085 806.158 566.45 809.025C568.94 811.14 572.371 811.873 575.67 812.409C582.212 813.471 588.838 814.054 595.474 814.148C596.949 814.166 598.481 814.148 599.797 813.527C602.589 812.202 603.331 808.734 603.143 805.773C602.673 798.357 598.256 791.271 591.648 787.304C585.041 783.338 576.403 782.595 569.147 785.368L569.241 785.669L569.232 785.659Z"
      fill="white"
    />
    <path
      d="M332.145 764.155C339.231 766.12 346.901 764.559 353.771 762.003C360.642 759.446 367.099 755.894 374.101 753.666C377.955 752.444 382.805 750.649 382.88 746.795C382.955 742.961 378.133 740.968 374.158 740.282C360.426 737.942 345.67 739.098 332.624 743.891C319.785 748.609 315.02 759.418 332.154 764.155H332.145Z"
      fill="white"
    />
    <path
      d="M490.299 755.987C498.787 758.356 507.913 755.715 515.94 752.19C519.004 750.846 522.143 749.258 523.957 746.56C525.78 743.863 525.715 739.765 522.961 737.913C521.748 737.095 520.244 736.832 518.778 736.597C511.325 735.413 484.378 729.811 478.758 735.751C472.188 742.688 483.57 754.107 490.29 755.987H490.299Z"
      fill="white"
    />
    <path
      d="M813.369 743.177C824.253 743.769 835.184 743.458 846.011 742.255C848.53 738.693 847.092 733.58 843.887 730.544C840.682 727.518 836.17 726.127 831.781 725.102C822.335 722.894 797.052 717.198 793.697 726.898C789.806 738.148 804.769 742.707 813.369 743.167V743.177Z"
      fill="currentColor"
    />
    <path
      d="M797.475 779.484C791.742 780.208 786.309 782.868 782.371 786.881C780.116 789.184 778.273 792.182 778.63 795.312C779.251 800.716 785.727 803.517 791.366 804.438C799.186 805.707 807.184 805.397 815.117 805.002C819.093 804.805 823.219 804.551 826.725 802.756C830.23 800.961 832.947 797.173 832.054 793.489C830.935 788.864 825.277 786.891 820.437 785.688C813.228 783.892 806.019 782.088 798.81 780.293L797.475 779.484Z"
      fill="currentColor"
    />
    <path
      d="M1480.07 1635.39C1251.97 1660.86 1015.25 1661.59 772.2 1644.12C709.97 1639.65 661.321 1588.68 660.4 1526.3L659.432 1460.7C658.37 1388.69 720.582 1332.4 792.211 1339.83C1015.4 1362.99 1234.72 1363.2 1448.92 1332.82C1520.45 1322.68 1584.66 1377.38 1585.73 1449.6L1586.69 1514.41C1587.6 1576.17 1541.45 1628.52 1480.07 1635.37V1635.39Z"
      fill="white"
    />
    <path
      d="M1093.04 1658.32C990.237 1658.32 883.615 1654.31 772.041 1646.29C741.372 1644.09 712.847 1630.64 691.737 1608.43C670.58 1586.17 658.681 1557.01 658.23 1526.34L657.261 1460.74C656.745 1425.99 671.153 1392.62 696.774 1369.17C722.603 1345.53 757.473 1334.05 792.437 1337.68C1028.2 1362.15 1242.84 1359.86 1448.62 1330.68C1483.4 1325.75 1518.63 1335.99 1545.29 1358.79C1571.86 1381.52 1587.4 1414.61 1587.91 1449.59L1588.87 1514.39C1589.8 1577.59 1543.14 1630.53 1480.31 1637.54C1356.25 1651.4 1227.94 1658.32 1093.04 1658.32V1658.32ZM779.71 1341.37C750.217 1341.37 721.597 1352.34 699.707 1372.37C674.997 1394.99 661.106 1427.17 661.604 1460.68L662.572 1526.27C663.484 1587.8 710.675 1637.52 772.351 1641.96C1030.06 1660.48 1261.48 1657.62 1479.83 1633.24C1540.41 1626.47 1585.42 1575.41 1584.52 1514.47L1583.56 1449.66C1583.06 1415.93 1568.08 1384.02 1542.46 1362.1C1516.75 1340.12 1482.76 1330.24 1449.23 1334.99C1243.1 1364.22 1028.1 1366.52 791.985 1342.02C787.887 1341.59 783.79 1341.38 779.71 1341.38V1341.37Z"
      fill="currentColor"
    />
    <path
      d="M1665.56 1628.89C1306.44 1681.99 957.2 1683.75 615.878 1644.26C580.51 1640.18 553.536 1609.48 552.99 1572.59L552.398 1532.52C551.74 1488.27 588.875 1453.73 631.264 1458.98C980.03 1502.2 1318.07 1500.01 1643.78 1445.54C1686.24 1438.45 1725.03 1471.7 1725.69 1516.31L1726.27 1555.39C1726.81 1592.26 1700.76 1623.68 1665.56 1628.89Z"
      fill="currentColor"
    />
    <path
      d="M1495.14 1696.15C1261.67 1722.85 1019.14 1723.48 770.002 1705.11C712.819 1700.9 668.456 1653.39 667.611 1596.06L666.37 1512.38C665.383 1445.68 723.036 1393.38 789.364 1400.46C1019.43 1425.02 1245.43 1425.33 1465.95 1393.06C1532.16 1383.37 1591.68 1434.33 1592.68 1501.23L1593.9 1584.11C1594.75 1641.31 1551.97 1689.65 1495.14 1696.15V1696.15Z"
      fill="white"
    />
    <path
      d="M1100.12 1719.99C994.448 1719.99 884.725 1715.75 769.833 1707.28C741.664 1705.21 715.47 1692.68 696.089 1671.99C676.737 1651.34 665.853 1624.39 665.43 1596.09L664.189 1512.42C663.71 1480.09 677.131 1449.07 701.005 1427.29C724.981 1405.42 757.267 1394.87 789.589 1398.31C1032.96 1424.3 1254.09 1421.89 1465.63 1390.93C1497.71 1386.24 1530.3 1395.62 1555.03 1416.67C1579.85 1437.8 1594.36 1468.62 1594.84 1501.22L1596.06 1584.1C1596.93 1642.56 1553.64 1691.66 1495.37 1698.33C1368.89 1712.78 1237.95 1720 1100.12 1720V1719.99ZM777.521 1402.01C750.424 1402.01 724.107 1412.08 703.937 1430.5C680.985 1451.44 668.08 1481.28 668.541 1512.35L669.781 1596.03C670.618 1652.83 713.778 1698.8 770.162 1702.95C1034.66 1722.44 1271.72 1719.51 1494.89 1694C1550.93 1687.59 1592.57 1640.37 1591.73 1584.16L1590.51 1501.28C1590.05 1469.92 1576.09 1440.29 1552.23 1419.97C1528.45 1399.72 1497.12 1390.71 1466.27 1395.22C1254.37 1426.23 1032.87 1428.65 789.138 1402.63C785.266 1402.21 781.384 1402.01 777.531 1402.01H777.521Z"
      fill="currentColor"
    />
    <path
      d="M1143.43 1448.03C1110.15 1448.03 1087.94 1430.32 1066.41 1413.16C1042.99 1394.5 1018.82 1375.2 979.372 1376.19C947.576 1376.98 926.24 1390.39 905.609 1403.36C882.967 1417.59 861.576 1431.03 828.548 1427.81C793.932 1424.43 760.923 1403.87 730.451 1366.69C729.69 1365.76 729.822 1364.39 730.752 1363.63C731.673 1362.87 733.045 1363.01 733.807 1363.93C763.526 1400.19 795.539 1420.22 828.961 1423.49C860.542 1426.57 881.304 1413.5 903.297 1399.68C924.426 1386.4 946.269 1372.66 979.259 1371.84C1020.24 1370.83 1045.09 1390.61 1069.1 1409.75C1092.48 1428.38 1114.55 1445.97 1150.07 1443.43C1182.56 1441.1 1202.04 1423.93 1222.66 1405.74C1242.8 1387.99 1263.6 1369.64 1297.16 1367.14C1331.44 1364.58 1354.92 1387.54 1375.61 1407.81C1393.51 1425.34 1408.96 1440.47 1427.57 1436.28C1450.27 1431.16 1467.98 1402.35 1480.23 1350.62C1480.5 1349.45 1481.68 1348.73 1482.84 1349C1484.01 1349.27 1484.73 1350.45 1484.45 1351.61C1471.79 1405.09 1452.97 1435 1428.52 1440.51C1407.59 1445.22 1390.58 1428.55 1372.57 1410.9C1352.54 1391.28 1329.83 1369.04 1297.47 1371.45C1265.37 1373.85 1246.01 1390.92 1225.52 1408.98C1205.27 1426.84 1184.32 1445.32 1150.36 1447.74C1147.99 1447.91 1145.67 1448 1143.4 1448L1143.43 1448.03Z"
      fill="currentColor"
    />
    <path
      d="M1562.36 1238.55C1561.9 1238.55 1561.43 1238.4 1561.03 1238.1C1560.08 1237.37 1559.91 1236.01 1560.65 1235.06C1568.58 1224.8 1576.38 1214.28 1583.82 1203.76C1584.52 1202.78 1585.87 1202.55 1586.85 1203.24C1587.83 1203.94 1588.06 1205.29 1587.37 1206.27C1579.89 1216.83 1572.05 1227.42 1564.08 1237.72C1563.64 1238.27 1563.01 1238.56 1562.36 1238.56V1238.55Z"
      fill="currentColor"
    />
    <path
      d="M1585.59 1207.18C1585.16 1207.18 1584.72 1207.05 1584.34 1206.78C1583.36 1206.09 1583.13 1204.74 1583.82 1203.76C1687.76 1057 1743.24 885.006 1740.03 719.464C1740.02 718.261 1740.96 717.274 1742.16 717.255C1742.18 717.255 1742.19 717.255 1742.21 717.255C1743.39 717.255 1744.35 718.195 1744.38 719.388C1745.99 803.048 1733.17 887.628 1706.25 970.78C1679.51 1053.41 1639.51 1132.65 1587.37 1206.27C1586.94 1206.87 1586.28 1207.19 1585.59 1207.19V1207.18Z"
      fill="currentColor"
    />
    <path
      d="M1579.04 1143.12C1578.66 1143.12 1578.26 1143.01 1577.91 1142.8C1576.89 1142.18 1576.57 1140.84 1577.19 1139.82C1644.83 1028.85 1683.65 906.031 1689.47 784.635C1689.52 783.432 1690.54 782.53 1691.74 782.568C1692.94 782.624 1693.87 783.639 1693.81 784.842C1687.96 906.952 1648.92 1030.48 1580.9 1142.07C1580.49 1142.74 1579.77 1143.12 1579.05 1143.12H1579.04Z"
      fill="currentColor"
    />
    <path
      d="M526.062 214.003C526.062 214.003 526.034 214.003 526.015 214.003C524.822 213.974 523.863 212.987 523.891 211.784L528.328 2.12414C528.356 0.939885 529.324 0 530.499 0C530.517 0 530.527 0 530.546 0C531.739 0.0281966 532.698 1.01508 532.67 2.21813L528.234 211.878C528.205 213.063 527.237 214.003 526.062 214.003Z"
      fill="currentColor"
    />
    <path
      d="M472.743 216.437C471.897 216.437 471.098 215.948 470.75 215.121L391.649 30.6405C391.179 29.5408 391.686 28.2626 392.786 27.7927C393.886 27.3227 395.164 27.8303 395.634 28.9299L474.735 213.411C475.205 214.51 474.698 215.789 473.598 216.258C473.316 216.381 473.025 216.437 472.743 216.437Z"
      fill="currentColor"
    />
    <path
      d="M427.787 238.064C420.607 238.064 415.221 236.166 411.734 232.397C408.689 229.098 408.313 225.639 407.975 222.584C407.702 220.094 407.448 217.734 405.813 215.319C402.702 210.723 397.542 210.262 391.564 209.736C385.859 209.228 379.383 208.655 374.693 203.749C369.007 197.809 370.2 190.327 371.356 183.09C372.409 176.483 373.405 170.242 369.495 165.091C365.05 159.226 357.267 158.869 349.025 158.503C340.114 158.098 330.904 157.685 325.283 150.081C320.574 143.718 321.514 136.34 322.351 129.836C323.112 123.849 323.77 118.67 320.414 114.986C315.668 109.779 305.075 109.515 288.928 114.196C287.772 114.535 286.569 113.867 286.24 112.711C285.911 111.555 286.569 110.352 287.725 110.023C305.846 104.778 317.595 105.436 323.629 112.053C328.338 117.223 327.492 123.905 326.665 130.372C325.866 136.622 325.114 142.524 328.789 147.487C333.178 153.427 340.979 153.775 349.241 154.151C358.113 154.555 367.296 154.969 372.973 162.46C378.001 169.086 376.761 176.887 375.661 183.767C374.58 190.525 373.65 196.361 377.851 200.741C381.413 204.463 386.545 204.924 391.968 205.403C398.453 205.976 405.164 206.569 409.431 212.885C411.668 216.184 412.007 219.332 412.307 222.105C412.618 224.943 412.862 227.19 414.939 229.436C418.21 232.98 424.094 234.333 432.393 233.449C433.615 233.327 434.658 234.183 434.78 235.376C434.912 236.57 434.047 237.641 432.853 237.764C431.086 237.952 429.395 238.046 427.806 238.046L427.787 238.064Z"
      fill="currentColor"
    />
    <path
      d="M1520.18 333.162C1517.21 333.162 1514.23 332.607 1511.37 331.498C1505.15 329.083 1500.25 324.336 1497.58 318.123C1492.11 305.454 1497.78 290.603 1510.21 285.02C1516.2 282.332 1522.85 282.163 1528.96 284.532C1535.19 286.947 1540.08 291.694 1542.76 297.906C1548.23 310.576 1542.56 325.426 1530.13 331.009C1526.94 332.438 1523.57 333.162 1520.18 333.162ZM1511.99 288.977C1501.71 293.592 1497.03 305.895 1501.57 316.403C1503.77 321.535 1507.82 325.455 1512.95 327.447C1517.97 329.402 1523.44 329.252 1528.35 327.052C1538.64 322.437 1543.32 310.134 1538.78 299.626C1536.57 294.495 1532.52 290.575 1527.39 288.583C1522.38 286.628 1516.91 286.778 1511.99 288.977Z"
      fill="currentColor"
    />
    <path
      d="M1467.17 213.815C1467.01 213.815 1466.86 213.796 1466.7 213.758C1465.52 213.495 1464.79 212.339 1465.05 211.164L1468.69 194.773C1468.95 193.598 1470.12 192.865 1471.28 193.128C1472.46 193.391 1473.19 194.547 1472.93 195.722L1469.29 212.113C1469.06 213.129 1468.16 213.815 1467.18 213.815H1467.17Z"
      fill="currentColor"
    />
    <path
      d="M1458.68 252.012C1458.52 252.012 1458.37 251.993 1458.21 251.955C1457.03 251.692 1456.3 250.536 1456.56 249.361L1460.2 232.97C1460.46 231.795 1461.63 231.062 1462.8 231.325C1463.96 231.588 1464.7 232.744 1464.44 233.919L1460.8 250.311C1460.58 251.326 1459.68 252.012 1458.69 252.012H1458.68Z"
      fill="currentColor"
    />
    <path
      d="M1453.84 222.293C1453.68 222.293 1453.53 222.274 1453.37 222.236L1436.98 218.599C1435.8 218.336 1435.07 217.18 1435.33 216.005C1435.59 214.83 1436.76 214.097 1437.93 214.36L1454.32 217.997C1455.49 218.26 1456.23 219.416 1455.96 220.591C1455.74 221.606 1454.83 222.293 1453.85 222.293H1453.84Z"
      fill="currentColor"
    />
    <path
      d="M1492.04 230.78C1491.88 230.78 1491.73 230.761 1491.57 230.723L1475.17 227.086C1474 226.823 1473.27 225.667 1473.53 224.492C1473.79 223.317 1474.96 222.584 1476.12 222.847L1492.51 226.484C1493.69 226.747 1494.42 227.904 1494.16 229.078C1493.93 230.093 1493.03 230.78 1492.04 230.78H1492.04Z"
      fill="currentColor"
    />
    <path
      d="M358.715 403.315H281.033C265.13 403.315 253.992 387.591 259.284 372.59L278.138 319.101L375.755 334.064V386.284C375.755 395.693 368.123 403.324 358.715 403.324V403.315Z"
      fill="currentColor"
    />
    <path
      d="M325.8 384.01C353.385 384.01 375.746 361.648 375.746 334.064C375.746 306.48 353.385 284.118 325.8 284.118C298.216 284.118 275.854 306.48 275.854 334.064C275.854 361.648 298.216 384.01 325.8 384.01Z"
      fill="currentColor"
    />
    <path
      d="M531.985 735.583L523.027 804.157L557.418 830.305L575.83 756.749L531.985 735.583Z"
      fill="white"
    />
    <path
      d="M557.418 832.476C556.948 832.476 556.487 832.326 556.102 832.034L521.711 805.887C521.091 805.417 520.771 804.646 520.875 803.875L529.822 735.301C529.907 734.605 530.33 734.004 530.941 733.675C531.552 733.346 532.285 733.327 532.915 733.628L576.761 754.794C577.691 755.245 578.17 756.279 577.926 757.276L559.514 830.831C559.335 831.536 558.818 832.11 558.123 832.354C557.888 832.439 557.643 832.476 557.408 832.476H557.418ZM525.349 803.189L556.121 826.583L573.302 757.933L533.751 738.844L525.349 803.199V803.189Z"
      fill="currentColor"
    />
    <path
      d="M362.437 690.196C362.437 690.196 520.461 486.203 579.599 511.373C638.737 536.543 614.807 702.734 578.339 768.15L524.831 754.23V722.011L490.948 781.947L362.428 690.186L362.437 690.196Z"
      fill="currentColor"
    />
    <path
      d="M575.709 780.416C596.029 794.599 645.148 830.136 666.615 860.269C671.85 867.619 667.235 877.901 658.269 878.803C626.134 882.055 561.018 877.271 515.208 838.839C515.208 838.839 515.819 824.121 525.171 797.916L549.533 807.945L547.691 804.251C544.918 798.697 546.3 791.957 551.027 787.935L558.932 781.215C563.697 777.164 570.577 776.835 575.699 780.416H575.709Z"
      fill="white"
    />
    <path
      d="M640.852 881.783C605.907 881.783 552.719 873.145 513.817 840.493C513.3 840.061 513.018 839.412 513.046 838.745C513.074 838.134 513.779 823.397 523.14 797.183C523.338 796.628 523.761 796.168 524.306 795.933C524.842 795.689 525.462 795.679 526.016 795.905L545.143 803.781C542.803 797.634 544.551 790.623 549.655 786.29L557.559 779.569C563.067 774.889 571.047 774.503 576.977 778.639C602.364 796.356 647.995 830.361 668.41 859.009C671.333 863.107 671.831 868.295 669.754 872.882C667.686 877.44 663.485 880.467 658.503 880.965C653.428 881.482 647.469 881.783 640.871 881.783H640.852ZM517.454 837.88C562.559 875.109 626.951 879.79 658.052 876.641C661.473 876.294 664.359 874.216 665.778 871.087C667.207 867.929 666.859 864.357 664.848 861.528C644.819 833.406 599.629 799.758 574.468 782.201C570.154 779.194 564.354 779.466 560.351 782.878L552.446 789.598C548.461 792.982 547.314 798.612 549.645 803.292L551.487 806.986C551.892 807.794 551.751 808.772 551.14 809.43C550.519 810.088 549.561 810.304 548.724 809.956L526.477 800.792C519.362 821.272 517.792 834.243 517.463 837.88H517.454Z"
      fill="currentColor"
    />
    <path
      d="M630.147 867.862C573.499 867.862 522.445 835.352 521.852 834.976C520.847 834.327 520.555 832.983 521.204 831.977C521.852 830.972 523.196 830.68 524.202 831.329C524.869 831.761 591.724 874.291 656.285 860.935C657.432 860.691 658.607 861.443 658.851 862.618C659.095 863.793 658.334 864.939 657.159 865.184C648.136 867.054 639.057 867.862 630.137 867.862H630.147Z"
      fill="currentColor"
    />
    <path
      d="M587.561 821.348C587.044 821.348 586.517 821.16 586.104 820.793C585.211 819.994 585.145 818.622 585.944 817.729L602.308 799.561C603.106 798.668 604.488 798.602 605.372 799.401C606.265 800.2 606.33 801.572 605.531 802.465L589.168 820.633C588.736 821.113 588.143 821.348 587.551 821.348H587.561Z"
      fill="currentColor"
    />
    <path
      d="M603.096 832.476C602.523 832.476 601.95 832.25 601.517 831.799C600.69 830.925 600.728 829.553 601.602 828.735L618.802 812.437C619.676 811.61 621.048 811.648 621.866 812.522C622.693 813.396 622.656 814.768 621.781 815.586L604.581 831.884C604.158 832.278 603.623 832.476 603.087 832.476H603.096Z"
      fill="currentColor"
    />
    <path
      d="M653.014 1115.79C653.014 1117.51 690.619 1199.55 690.619 1199.55L647.891 1202.97L618.83 1132.89L653.014 1115.79Z"
      fill="white"
    />
    <path
      d="M647.882 1205.14C647.008 1205.14 646.219 1204.61 645.88 1203.81L616.819 1133.73C616.387 1132.68 616.838 1131.46 617.853 1130.96L652.037 1113.86C652.713 1113.52 653.512 1113.56 654.151 1113.95C654.678 1114.28 655.044 1114.82 655.148 1115.42C656.247 1118.94 678.081 1167 692.593 1198.65C692.884 1199.29 692.847 1200.04 692.49 1200.65C692.132 1201.26 691.493 1201.65 690.788 1201.71L648.061 1205.13C648.005 1205.13 647.948 1205.13 647.892 1205.13L647.882 1205.14ZM621.603 1133.93L649.283 1200.68L687.348 1197.63C661.971 1142.23 654.236 1124.59 651.877 1118.79L621.603 1133.93Z"
      fill="currentColor"
    />
    <path
      d="M651.304 1182.46C651.304 1182.46 659.847 1161.95 682.066 1172.21C682.066 1172.21 722.858 1231.12 738.018 1279.77C742.304 1293.53 728.177 1305.92 715.047 1299.99C684.313 1286.11 636.134 1256.98 608.576 1202.97C608.576 1202.97 617.12 1180.75 630.795 1170.5L651.304 1182.46Z"
      fill="white"
    />
    <path
      d="M721.993 1303.68C719.352 1303.68 716.692 1303.11 714.154 1301.97C680.064 1286.58 633.746 1257.08 606.64 1203.95C606.358 1203.4 606.33 1202.75 606.546 1202.18C606.903 1201.25 615.475 1179.26 629.489 1168.75C630.184 1168.22 631.134 1168.17 631.885 1168.61L650.42 1179.42C652.159 1176.42 656.003 1171.14 662.545 1168.58C668.616 1166.21 675.487 1166.76 682.968 1170.21C683.316 1170.37 683.626 1170.63 683.843 1170.95C684.256 1171.54 724.991 1230.7 740.076 1279.1C742.313 1286.27 740.18 1293.83 734.531 1298.84C730.94 1302.02 726.495 1303.66 721.983 1303.66L721.993 1303.68ZM610.954 1202.86C637.553 1254.31 682.677 1282.99 715.93 1298.01C721.241 1300.41 727.265 1299.49 731.655 1295.6C736.016 1291.74 737.651 1285.91 735.931 1280.41C721.767 1234.96 684.557 1179.71 680.609 1173.93C674.453 1171.2 668.908 1170.77 664.114 1172.64C656.576 1175.58 653.324 1183.21 653.296 1183.29C653.061 1183.87 652.582 1184.31 651.98 1184.51C651.388 1184.71 650.74 1184.64 650.194 1184.33L630.955 1173.11C620.071 1182.01 612.627 1198.82 610.945 1202.86H610.954Z"
      fill="currentColor"
    />
    <path
      d="M725.489 1287.33C714.023 1287.33 671.962 1281.61 617.073 1205.96C616.368 1204.99 616.584 1203.63 617.552 1202.92C618.52 1202.22 619.883 1202.43 620.588 1203.4C682.094 1288.17 727.472 1282.93 727.914 1282.86C729.089 1282.69 730.198 1283.52 730.367 1284.7C730.536 1285.89 729.728 1286.99 728.544 1287.15C728.29 1287.19 727.247 1287.32 725.489 1287.32V1287.33Z"
      fill="currentColor"
    />
    <path
      d="M690.619 1241.03C689.876 1241.03 689.143 1240.65 688.739 1239.95C688.138 1238.92 688.485 1237.58 689.519 1236.98L710.028 1225.02C711.062 1224.42 712.396 1224.76 712.998 1225.8C713.599 1226.83 713.252 1228.17 712.218 1228.77L691.709 1240.73C691.361 1240.93 690.986 1241.03 690.619 1241.03Z"
      fill="currentColor"
    />
    <path
      d="M680.365 1225.65C679.594 1225.65 678.842 1225.23 678.447 1224.5C677.884 1223.45 678.278 1222.13 679.331 1221.56L701.55 1209.6C702.603 1209.02 703.919 1209.43 704.492 1210.48C705.056 1211.53 704.661 1212.85 703.608 1213.42L681.389 1225.39C681.06 1225.56 680.713 1225.65 680.365 1225.65Z"
      fill="currentColor"
    />
    <path
      d="M666.689 1131.18L608.576 1148.28C608.576 1148.28 502.603 924.368 471.831 891.895C441.068 859.422 381.244 806.431 317.999 787.633C254.754 768.836 254.754 720.977 254.754 720.977L392.739 671.67C392.739 671.67 512.857 755.17 562.418 828.659C611.988 902.159 666.68 1131.2 666.68 1131.2L666.689 1131.18Z"
      fill="currentColor"
    />
    <path
      d="M608.567 1150.44C607.749 1150.44 606.969 1149.97 606.602 1149.2C605.54 1146.96 500.432 925.233 470.252 893.38C452.235 874.366 387.974 810.689 317.379 789.701C253.326 770.659 252.583 721.456 252.583 720.958C252.583 720.037 253.156 719.219 254.021 718.919L392.006 669.612C392.664 669.377 393.407 669.471 393.98 669.875C395.183 670.712 515 754.446 564.223 827.438C613.445 900.42 668.25 1128.39 668.795 1130.68C669.067 1131.81 668.4 1132.95 667.3 1133.27L609.187 1150.36C608.98 1150.42 608.773 1150.45 608.576 1150.45L608.567 1150.44ZM256.991 722.462C257.574 730.695 263.279 769.09 318.62 785.538C390.258 806.835 455.205 871.18 473.41 890.391C503.007 921.624 599.224 1123.49 609.741 1145.66L664.095 1129.68C658.559 1106.93 606.959 898.549 560.632 829.853C514.117 760.884 403.623 681.972 392.42 674.067L257 722.462H256.991Z"
      fill="white"
    />
    <path
      d="M438.352 464.567C427.684 452.104 414.827 442.996 402.448 436.389L402.279 435.524C324.165 383.135 233.052 433.692 233.052 433.692L236.708 652.939C236.652 660.636 236.708 705.159 252.63 743.205C302.181 861.593 332.088 714.764 447.037 659.198L442.864 638.333C474.35 653.578 518.638 632.252 517.632 588.162C516.401 534.391 470.12 501.692 438.361 464.576L438.352 464.567Z"
      fill="white"
    />
    <path
      d="M291.09 790.293C289.897 790.293 288.712 790.199 287.519 789.993C274.567 787.831 262.847 773.225 250.628 744.032C235.345 707.508 234.443 665.054 234.537 652.91L230.881 433.72C230.872 432.921 231.295 432.178 231.999 431.783C232.921 431.276 325.18 381.189 403.492 433.71C403.924 434.002 404.244 434.443 404.375 434.941C418.624 442.677 430.608 452.16 440.007 463.138C447.046 471.371 454.998 479.577 462.686 487.509C490.291 516.007 518.826 545.463 519.803 588.086C520.264 608.379 511.222 626.001 494.99 636.444C480.074 646.03 461.596 648.014 445.806 641.951L449.171 658.747C449.368 659.715 448.879 660.693 447.986 661.125C403.078 682.836 371.413 718.42 345.98 747.012C323.874 771.853 307.491 790.275 291.1 790.275L291.09 790.293ZM235.233 434.988L238.87 652.901C238.785 664.884 239.65 706.597 254.623 742.359C266.211 770.048 276.889 783.818 288.224 785.707C303.459 788.244 320.791 768.779 342.719 744.136C368.124 715.572 399.676 680.111 444.565 657.967L440.721 638.746C440.561 637.938 440.871 637.111 441.52 636.603C442.168 636.096 443.052 636.011 443.794 636.368C459.021 643.737 477.734 642.374 492.622 632.797C507.557 623.201 515.865 606.941 515.442 588.199C514.512 547.277 486.569 518.431 459.547 490.545C451.812 482.565 443.823 474.313 436.689 465.977C427.45 455.177 415.579 445.872 401.415 438.306C400.851 438.006 400.447 437.47 400.315 436.84C329.006 389.677 245.9 429.518 235.233 434.998V434.988Z"
      fill="currentColor"
    />
    <path
      d="M506.626 475.761C506.626 475.761 518.929 566 513.694 582.166C508.449 598.332 501.109 617.844 481.277 600.334C461.445 582.824 484.153 495.696 484.153 495.696L506.635 475.761H506.626Z"
      fill="white"
    />
    <path
      d="M495.131 609.394C490.516 609.394 485.479 606.941 479.83 601.951C459.227 583.764 481.099 498.751 482.039 495.141C482.151 494.728 482.377 494.352 482.696 494.06L505.179 474.125C505.78 473.59 506.617 473.439 507.369 473.712C508.12 473.994 508.656 474.661 508.769 475.451C509.277 479.154 521.082 566.366 515.743 582.814C511.889 594.695 507.387 606.49 498.458 608.934C497.368 609.235 496.259 609.385 495.122 609.385L495.131 609.394ZM486.08 496.871C479.802 521.242 467.395 585.183 482.706 598.699C488.477 603.793 493.392 605.832 497.321 604.761C504.164 602.89 508.29 591.772 511.626 581.489C515.48 569.59 509.267 512.172 505.019 480.084L486.08 496.871Z"
      fill="currentColor"
    />
    <path
      d="M428.05 596.151C427.045 596.151 426.142 595.446 425.926 594.422L403.858 489.173C403.613 487.998 404.365 486.851 405.54 486.607C406.705 486.363 407.862 487.115 408.106 488.29L430.175 593.538C430.419 594.713 429.667 595.86 428.492 596.104C428.342 596.133 428.191 596.151 428.041 596.151H428.05Z"
      fill="currentColor"
    />
    <path
      d="M347.258 388.38C364.016 385.062 370.999 332.908 371.225 332.184C371.225 332.156 371.234 332.137 371.244 332.109L316.758 328.594C317.811 331.46 318.845 334.073 319.136 335.878C320.133 342.015 309.878 334.336 305.442 336.319C302.51 337.635 301.382 341.63 304.248 347.091C306.044 350.512 309.051 353.839 311.721 355.437C312.143 358.67 312.999 361.875 314.202 364.958L314.07 364.892L299.399 410.148L269.566 423.428C341.769 455.479 364.627 423.551 364.627 423.551L337.201 413.165L340.303 388.577C342.587 388.888 344.908 388.85 347.239 388.389L347.258 388.38Z"
      fill="white"
    />
    <path
      d="M326.617 439.961C312.199 439.961 293.232 436.305 268.692 425.412C267.912 425.064 267.404 424.284 267.404 423.429C267.404 422.573 267.912 421.793 268.692 421.445L297.641 408.559L311.824 364.798C310.837 362.11 310.132 359.403 309.709 356.715C306.927 354.769 304.107 351.499 302.321 348.096C299.925 343.538 300.206 340.305 300.864 338.406C301.504 336.564 302.81 335.116 304.539 334.336C307.209 333.143 310.667 334.233 313.722 335.192C314.709 335.502 316.081 335.934 316.955 336.084C316.72 334.769 315.978 332.785 315.198 330.68L314.7 329.327C314.446 328.641 314.559 327.87 315 327.278C315.442 326.686 316.138 326.357 316.88 326.413L371.366 329.928C372.033 329.975 372.644 330.323 373.02 330.868C373.396 331.423 373.499 332.118 373.292 332.748C373.292 332.748 373.255 333.058 373.18 333.519C369.993 353.538 362.427 387.572 347.662 390.495C345.885 390.852 344.043 390.983 342.201 390.899L339.578 411.717L365.407 421.502C366.036 421.737 366.516 422.254 366.713 422.902C366.911 423.551 366.798 424.246 366.403 424.791C365.754 425.694 355.068 439.933 326.617 439.942V439.961ZM274.98 423.4C330.048 446.456 354.166 430.6 360.848 424.434L336.449 415.186C335.509 414.828 334.935 413.879 335.067 412.883L338.169 388.295C338.244 387.722 338.535 387.196 338.996 386.848C339.456 386.491 340.039 386.34 340.612 386.416C342.737 386.698 344.832 386.641 346.834 386.246C355.481 384.536 363.64 365.08 368.706 334.12L319.954 330.971C320.574 332.701 321.081 334.233 321.288 335.53C321.58 337.325 321.204 338.688 320.16 339.581C318.299 341.188 315.592 340.333 312.444 339.336C310.357 338.679 307.509 337.776 306.344 338.303C305.695 338.594 305.244 339.101 305 339.825C304.474 341.348 304.906 343.632 306.194 346.085C307.773 349.093 310.508 352.175 312.857 353.576C313.431 353.914 313.816 354.497 313.901 355.164C314.286 358.144 315.076 361.17 316.25 364.168C316.542 364.901 316.401 365.719 315.921 366.321L301.494 410.815C301.306 411.398 300.874 411.877 300.31 412.131L274.999 423.4H274.98Z"
      fill="currentColor"
    />
    <path
      d="M323.845 375.475C323.845 375.475 327.228 387.524 340.321 388.577L323.845 375.475Z"
      fill="white"
    />
    <path
      d="M340.321 390.739C340.265 390.739 340.209 390.739 340.143 390.739C328.592 389.809 323.056 380.729 321.749 376.058C321.42 374.902 322.097 373.708 323.253 373.379C324.4 373.06 325.603 373.727 325.932 374.874C326.063 375.325 329.137 385.485 340.491 386.406C341.684 386.5 342.577 387.553 342.483 388.746C342.389 389.884 341.44 390.739 340.321 390.739Z"
      fill="currentColor"
    />
    <path
      d="M528.676 470.949C494.436 456.851 460.9 438.579 427.882 417.244C419.432 411.783 416.5 400.796 421.218 391.904L426.171 382.552C431.613 372.289 444.781 369.15 454.33 375.766C484.097 396.359 515.104 413.409 547.756 425.74C558.658 429.857 563.499 442.574 558.038 452.875L553.141 462.114C548.479 470.921 537.887 474.746 528.676 470.949Z"
      fill="white"
    />
    <path
      d="M536.101 474.596C533.347 474.596 530.556 474.07 527.858 472.951C494.943 459.398 461.859 441.775 426.707 419.067C417.224 412.939 414.037 400.824 419.301 390.889L424.254 381.537C427.121 376.124 432.177 372.186 438.127 370.738C444.114 369.281 450.477 370.456 455.571 373.981C486.888 395.655 517.303 411.915 548.526 423.71C554.316 425.9 558.893 430.468 561.074 436.249C563.254 442.01 562.85 448.448 559.965 453.89L555.068 463.129C551.224 470.395 543.846 474.596 536.101 474.596ZM443.23 374.47C441.867 374.47 440.495 374.629 439.151 374.958C434.405 376.114 430.373 379.254 428.089 383.577L423.135 392.929C418.934 400.862 421.481 410.533 429.066 415.43C463.983 437.987 496.842 455.497 529.512 468.947C537.736 472.331 547.069 468.957 551.233 461.109L556.13 451.87C558.433 447.518 558.752 442.386 557.013 437.79C555.265 433.175 551.619 429.528 546.994 427.78C515.433 415.862 484.717 399.433 453.099 377.562C450.176 375.541 446.727 374.479 443.23 374.479V374.47Z"
      fill="currentColor"
    />
    <path
      d="M555.415 484.427C500.507 464.069 450.975 437.217 405.766 405.138C401.085 401.811 399.647 395.382 402.429 390.119L405.456 384.404C408.802 378.098 416.725 376.096 422.317 380.128C468.287 413.306 516.269 439.228 566.562 456.804C573.123 459.097 576.027 466.814 572.653 473.168L569.702 478.741C566.92 483.995 560.792 486.42 555.415 484.427Z"
      fill="white"
    />
    <path
      d="M559.269 487.285C557.728 487.285 556.167 487.021 554.654 486.458C501.814 466.861 451.294 440.102 404.497 406.905C398.933 402.958 397.213 395.307 400.503 389.104L403.529 383.389C405.456 379.752 408.821 377.139 412.759 376.218C416.537 375.334 420.475 376.124 423.577 378.361C470.233 412.037 518.581 437.734 567.268 454.746C570.933 456.024 573.847 458.844 575.275 462.481C576.76 466.259 576.497 470.517 574.561 474.173L571.61 479.747C569.1 484.474 564.26 487.275 559.26 487.275L559.269 487.285ZM415.87 380.203C415.174 380.203 414.46 380.287 413.755 380.447C411.039 381.077 408.717 382.891 407.383 385.419L404.356 391.134C402.082 395.42 403.228 400.674 407.026 403.362C453.513 436.333 503.693 462.923 556.177 482.388C560.538 484.004 565.529 482.002 567.794 477.726L570.745 472.152C572.089 469.624 572.268 466.682 571.253 464.079C570.275 461.579 568.358 459.727 565.858 458.853C516.777 441.7 468.053 415.806 421.058 381.885C419.517 380.776 417.731 380.203 415.879 380.203H415.87Z"
      fill="currentColor"
    />
    <path
      d="M526.092 480.715C490.996 466.372 456.652 447.64 422.835 425.703C415.072 420.665 412.487 410.505 416.82 402.328L423.136 390.401C428.174 380.889 440.383 377.966 449.218 384.113C479.849 405.439 511.786 423.024 545.481 435.572C555.595 439.341 560.059 451.165 555.012 460.705L548.752 472.519C544.429 480.677 534.635 484.192 526.092 480.696V480.715Z"
      fill="white"
    />
    <path
      d="M532.952 484.239C530.396 484.239 527.793 483.751 525.274 482.717C491.579 468.938 457.686 450.892 421.66 427.517C412.9 421.84 409.996 410.571 414.902 401.313L421.218 389.386C423.906 384.32 428.634 380.635 434.208 379.282C439.8 377.928 445.721 379.037 450.458 382.336C482.734 404.809 514.061 421.558 546.243 433.542C551.637 435.553 555.905 439.792 557.954 445.177C560.012 450.582 559.636 456.616 556.929 461.729L550.669 473.543C547.088 480.311 540.19 484.239 532.943 484.239H532.952ZM526.918 478.703C534.485 481.796 543.038 478.703 546.844 471.513L553.104 459.699C555.228 455.695 555.519 450.967 553.903 446.728C552.295 442.508 548.959 439.19 544.729 437.611C512.2 425.496 480.554 408.578 447.977 385.899C444.264 383.314 439.621 382.44 435.232 383.502C430.871 384.564 427.158 387.44 425.053 391.416L418.737 403.343C414.959 410.486 417.271 419.5 424.019 423.88C459.82 447.095 493.477 465.028 526.918 478.703Z"
      fill="currentColor"
    />
    <path
      d="M538.301 441.456C537.511 441.456 536.75 441.352 536.007 441.136C531.731 439.905 530.415 435.572 529.155 431.38C527.896 427.226 526.599 422.93 522.586 421.107C518.422 419.208 514.822 420.017 510.649 420.947C506.316 421.925 501.4 423.024 495.855 420.477C489.736 417.667 487.706 412.808 485.742 408.108C483.928 403.766 482.208 399.659 477.151 397.036C473.034 394.912 469.397 395.128 465.553 395.354C461.135 395.617 456.558 395.89 451.624 392.515C446.567 389.066 443.24 383.201 441.727 375.09C441.511 373.915 442.281 372.778 443.466 372.562C444.659 372.336 445.778 373.116 446.003 374.3C447.3 381.237 450.017 386.162 454.077 388.934C457.789 391.472 461.276 391.265 465.299 391.021C469.369 390.777 473.974 390.504 479.153 393.183C485.62 396.519 487.819 401.792 489.765 406.435C491.56 410.721 493.111 414.434 497.678 416.53C501.899 418.466 505.517 417.657 509.718 416.708C514.023 415.74 518.911 414.65 524.4 417.15C530.199 419.791 531.937 425.515 533.329 430.12C534.503 434.012 535.359 436.418 537.229 436.963C540.039 437.771 544.382 435.102 549.466 429.463C550.275 428.57 551.638 428.504 552.53 429.303C553.423 430.102 553.489 431.483 552.69 432.367C547.192 438.457 542.464 441.446 538.319 441.446L538.301 441.456Z"
      fill="currentColor"
    />
    <path
      d="M408.369 546.431C397.392 551.676 385.436 555.379 372.607 556.563C303.431 562.973 288.289 453.617 288.289 453.617L233.033 433.682C153.781 505.132 274.529 653.193 369.665 636.688C402.834 630.936 428.286 615.127 447.695 595.728C415.757 585.248 409.037 561.939 408.36 546.422L408.369 546.431Z"
      fill="white"
    />
    <path
      d="M354.307 640.175C298.976 640.175 242.253 592.513 218.201 543.066C196.941 499.361 201.829 458.909 231.585 432.075C232.478 431.276 233.841 431.342 234.65 432.235C235.448 433.128 235.383 434.5 234.49 435.299C206.265 460.741 201.744 499.333 222.093 541.167C247.742 593.914 311.316 644.611 369.289 634.554C397.486 629.667 422.487 616.913 443.672 596.621C414.808 585.756 407.759 563.894 406.405 549.711C395.324 554.645 384.036 557.681 372.795 558.715C350.03 560.82 330.631 551.13 315.076 529.917C314.371 528.949 314.578 527.596 315.546 526.881C316.505 526.176 317.867 526.383 318.582 527.351C333.169 547.258 351.281 556.356 372.39 554.392C384.102 553.301 395.888 549.965 407.42 544.457C408.078 544.147 408.849 544.184 409.469 544.551C410.099 544.927 410.484 545.594 410.522 546.318C411.227 562.484 418.361 583.819 448.362 593.66C449.076 593.895 449.621 594.487 449.8 595.22C449.978 595.953 449.753 596.724 449.227 597.26C426.876 619.601 400.23 633.586 370.031 638.822C364.824 639.724 359.561 640.156 354.288 640.156L354.307 640.175Z"
      fill="currentColor"
    />
    <path
      d="M514.079 416.708L502.641 401.641L490.892 448.871L490.789 428.926C490.789 428.926 485.083 434.979 482.705 443.861C480.403 452.47 483.232 473.449 483.41 474.717C475.515 487.481 448.54 527.257 408.379 546.44C409.056 561.958 412.853 584.252 444.79 594.731C490.854 548.686 505.742 483.421 505.742 483.421C524.155 457.442 514.079 416.717 514.079 416.717V416.708Z"
      fill="white"
    />
    <path
      d="M444.781 596.893C444.555 596.893 444.33 596.856 444.104 596.78C411.988 586.244 406.978 564.392 406.198 546.525C406.161 545.651 406.649 544.842 407.43 544.476C446.567 525.791 473.278 486.814 481.145 474.229C480.562 469.783 478.391 451.559 480.6 443.297C483.091 433.992 488.946 427.704 489.2 427.441C489.811 426.793 490.751 426.586 491.578 426.915C492.405 427.244 492.95 428.033 492.95 428.926L492.969 431.539L500.535 401.124C500.733 400.316 501.39 399.686 502.208 399.527C503.016 399.357 503.872 399.677 504.379 400.344L515.818 415.411C515.996 415.646 516.128 415.918 516.194 416.2C516.617 417.892 526.213 457.847 507.763 484.332C506.353 490.094 490.366 552.249 446.332 596.263C445.918 596.677 445.364 596.903 444.8 596.903L444.781 596.893ZM410.606 547.765C411.518 563.715 416.556 582.663 444.189 592.241C488.552 547.201 503.468 483.581 503.609 482.941C503.674 482.66 503.787 482.406 503.956 482.171C520.611 458.674 513.036 422.009 512.058 417.666L503.646 406.585L492.988 449.416C492.725 450.478 491.71 451.183 490.619 451.051C489.538 450.92 488.711 449.998 488.711 448.908L488.636 435.562C487.217 437.912 485.722 440.957 484.792 444.444C482.593 452.658 485.431 473.552 485.553 474.436C485.619 474.934 485.516 475.441 485.253 475.874C477.856 487.819 450.806 528 410.616 547.775L410.606 547.765Z"
      fill="currentColor"
    />
  </svg>
);
const EmptyCatalog = memo(SvgComponent);
export default EmptyCatalog;
