import { memo } from "react";
const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1800"
    height="1720"
    viewBox="0 0 1800 1720"
  >
    <path
      fill="currentColor"
      d="m1105.26 1164.98-337.294 2.11c-2.19 0-2.904 2.03-2.904 3.99v286.96c0 27.48-191.644 98.86-193.505 95.23l-.235 30.37c-.009 5.66 4.953 10.67 11.298 10.67h287.099c13.121 0 26.082-2.94 37.962-7.92l155.489-65.47c27.99-11.79 45.65-37.1 45.65-64.71v-287.26c0-1.94-1.37-3.98-3.56-3.98v.01Z"
    />
    <path
      fill="#fff"
      d="M730.907 1150.37v287.03c0 28.29-18.206 54.07-46.816 66.29l-104.892 44.77c-6.852 2.92-4.521 12.11 3.083 12.11h288.189c12.961 0 25.763-2.56 37.483-7.5l116.216-48.93c28.83-12.13 47.21-38.01 47.21-66.46v-287.31c0-1.18-1.07-2.12-2.38-2.12H733.304c-1.316 0-2.388.94-2.388 2.12h-.009Z"
    />
    <path
      fill="currentColor"
      d="M870.471 1562.63H582.282c-5.273 0-8.347-3.46-9.052-6.89-.789-3.84 1.288-7.53 5.16-9.19l104.892-44.76c27.68-11.82 45.566-37.09 45.566-64.39v-287.03c0-2.31 2.002-4.2 4.455-4.2h335.687c2.46 0 4.46 1.88 4.46 4.2v287.31c0 29.13-19.03 55.97-48.48 68.37l-116.217 48.93c-11.899 5.01-25.133 7.66-38.282 7.66v-.01Zm-137.487-412.21v286.98c0 28.96-18.873 55.73-48.076 68.19l-104.892 44.77c-2.105.9-3.129 2.6-2.725 4.55.366 1.78 2.049 3.58 4.991 3.58h288.189c12.604 0 25.283-2.54 36.674-7.33l116.215-48.93c27.92-11.75 45.95-37.09 45.95-64.55v-287.32c0 .06-.11-.06-.32-.06H733.303c-.178 0-.282.07-.319.11v.01Z"
    />
    <path
      fill="currentColor"
      d="m102.598 1313.02 1386.892 3.02 24.08.06 44.78.09c12.72 0 23.18-9.93 24.79-22.58l115.97-906.235c2.82-22.05-13.26-42.868-35.44-42.868l-1436.913 1.306-124.159 967.207Z"
    />
    <path
      fill="#fff"
      d="M1609.77 344.291c25.01 0 45.35 25.189 42.14 50.058l-116.79 899.291c-1.62 12.64-12.37 22.11-25.08 22.11l-10.95.42-1415.721-.42c-15.255 0-27.041-13.44-25.086-28.6l119.15-917.586c1.626-12.641 11.354-25.273 24.07-25.273H1609.77Z"
    />
    <path
      fill="currentColor"
      d="m1499.16 1318.24-1415.792-.41a27.34 27.34 0 0 1-20.527-9.29 27.491 27.491 0 0 1-6.617-21.65l119.159-917.589c1.711-13.309 11.956-27.078 26.12-27.078H1609.76c11.95 0 23.73 5.602 32.33 15.377 9.09 10.329 13.53 24.164 11.87 37.012l-116.79 899.298c-1.76 13.64-13.42 23.92-27.13 23.92l-10.87.41h-.01ZM201.503 346.368c-11.74 0-20.565 12.181-22.013 23.469L60.34 1287.42a23.334 23.334 0 0 0 5.621 18.39 23.2 23.2 0 0 0 17.416 7.88l1415.723.43 10.86-.42c11.72 0 21.62-8.73 23.11-20.31l116.79-899.295c1.51-11.682-2.56-24.296-10.87-33.751-7.81-8.882-18.46-13.976-29.22-13.976H201.503Z"
    />
    <path
      fill="#fff"
      d="M1545.85 390.449c22.88 0 41.48 22.501 38.56 44.71l-106.85 803.211c-1.48 11.3-11.31 19.75-22.95 19.75l-10.02.37-1295.196-.37c-13.957 0-24.738-12-22.952-25.55L235.45 413.016c1.485-11.298 10.386-22.577 22.022-22.577H1545.86l-.01.01Z"
    />
    <path
      fill="currentColor"
      d="m1444.67 1260.56-1295.275-.36c-7.35 0-14.324-3.14-19.118-8.61-4.652-5.31-6.795-12.34-5.884-19.29l109.009-819.557c1.579-11.974 11.015-24.371 24.071-24.371H1545.85c11.18 0 22.15 5.188 30.09 14.239 8.2 9.352 12.03 21.307 10.52 32.812l-106.85 803.207c-1.61 12.29-12.37 21.56-25 21.56l-9.93.36-.01.01ZM257.463 392.517c-10.639 0-18.647 10.78-19.973 20.771L128.482 1232.84c-.761 5.77 1.024 11.6 4.887 16.01 4.014 4.58 9.841 7.2 16.007 7.2l1295.194.38 9.94-.37c10.64 0 19.62-7.72 20.98-17.95l106.85-803.214c1.37-10.31-2.11-21.082-9.53-29.531-7.15-8.158-16.98-12.83-26.98-12.83H257.463v-.018Z"
    />
    <path
      fill="#fff"
      d="m1275.49 1166.68-862.061-15.21c-3.064-.06-5.122-2.35-4.803-5.36l96.433-892.895c.386-3.59 3.967-6.701 7.623-6.635l862.058 15.207c3.06.056 5.12 2.35 4.8 5.357l-96.43 892.896c-.39 3.59-3.97 6.7-7.62 6.64Z"
    />
    <path
      fill="currentColor"
      d="M1275.61 1168.75h-.15l-862.058-15.2c-2.087-.04-3.938-.86-5.216-2.31-1.26-1.43-1.824-3.32-1.608-5.34l96.433-892.901c.508-4.68 4.963-8.562 9.719-8.478l862.06 15.208c2.08.037 3.94.855 5.21 2.303 1.25 1.428 1.83 3.327 1.6 5.348l-96.43 892.9c-.5 4.63-4.86 8.47-9.57 8.47h.01ZM512.579 248.657c-2.547 0-5.188 2.303-5.451 4.784l-96.433 892.899c-.094.86.113 1.61.602 2.17.498.56 1.268.88 2.18.89l862.063 15.21c2.58-.05 5.25-2.29 5.52-4.79l96.44-892.892c.09-.864-.12-1.616-.61-2.161-.49-.564-1.27-.884-2.18-.893l-862.056-15.208h-.075v-.009Z"
    />
    <path
      fill="#fff"
      d="M1172.43 739.917H637.135l53.462 236.428c3.873 17.113 20.298 29.245 39.597 29.245h334.766c19.31 0 36.4-12.132 41.19-29.245l66.27-236.428h.01Z"
    />
    <path
      fill="currentColor"
      d="M1064.98 1009.03H730.212c-21.048 0-38.707-13.136-42.954-31.919l-53.463-236.428a3.418 3.418 0 0 1 .672-2.905 3.457 3.457 0 0 1 2.685-1.296h535.298c1.08 0 2.09.5 2.73 1.359.66.859.86 1.967.58 3.013l-66.27 236.428c-5.24 18.69-23.53 31.758-44.5 31.758l-.01-.01ZM641.446 743.353l52.526 232.227c3.528 15.646 18.44 26.56 36.24 26.56h334.768c17.89 0 33.46-10.992 37.88-26.731l65.04-232.056H641.446Z"
    />
    <path
      fill="#fff"
      d="M1174.74 723.68H635.699c-11.18 0-20.002-9.072-19.705-20.252.296-11.179 9.618-20.251 20.798-20.251h539.048c11.17 0 20 9.072 19.7 20.251-.3 11.18-9.62 20.252-20.8 20.252Z"
    />
    <path
      fill="currentColor"
      d="M1174.74 727.13H635.698c-6.34 0-12.242-2.483-16.629-6.964-4.372-4.497-6.699-10.477-6.527-16.816.359-13.007 11.227-23.593 24.249-23.593h539.039c6.34 0 12.25 2.482 16.63 6.964 4.38 4.497 6.7 10.477 6.53 16.816-.36 13.007-11.23 23.593-24.23 23.593h-.02Zm-537.949-40.503c-9.322 0-17.113 7.588-17.363 16.91-.125 4.466 1.499 8.681 4.575 11.836 3.06 3.154 7.214 4.887 11.695 4.887h539.042c9.32 0 17.11-7.589 17.36-16.91.13-4.466-1.49-8.682-4.57-11.836-3.06-3.154-7.21-4.887-11.7-4.887H636.791Z"
    />
    <path
      fill="#fff"
      d="M807.023 781.826h-54.899c-10.274 0-18.143 8.447-16.16 17.332l34.569 154.907c1.452 6.48 7.807 11.086 15.302 11.086h15.598c8.854 0 16.223-6.371 16.458-14.209l4.731-154.907c.234-7.854-6.746-14.209-15.599-14.209Z"
    />
    <path
      fill="currentColor"
      d="M801.436 968.601h-15.598c-9.165 0-16.832-5.667-18.643-13.787l-34.57-154.907c-1.093-4.856.156-9.977 3.404-14.037 3.763-4.684 9.775-7.495 16.114-7.495h54.899c5.512 0 10.727 2.124 14.318 5.824 3.17 3.279 4.856 7.511 4.715 11.93l-4.731 154.907c-.296 9.68-9.228 17.55-19.892 17.55l-.016.015Zm-49.309-183.324c-4.262 0-8.275 1.842-10.742 4.918-1.968 2.452-2.701 5.372-2.061 8.229l34.569 154.907c1.093 4.871 6.105 8.4 11.945 8.4h15.598c6.996 0 12.835-4.887 13.023-10.883l4.731-154.907c.078-2.545-.906-4.996-2.78-6.932-2.311-2.374-5.714-3.732-9.368-3.732h-54.915Z"
    />
    <path
      fill="#fff"
      d="M1077.11 799.157c2.53-8.885-4.85-17.332-15.16-17.332h-55.09c-8.881 0-16.266 6.371-16.516 14.209l-4.746 154.907c-.235 7.854 6.76 14.209 15.642 14.209h15.66c7.53 0 14.18-4.606 16.02-11.086l44.18-154.907h.01Z"
    />
    <path
      fill="currentColor"
      d="M1016.92 968.6h-15.66c-5.527 0-10.773-2.139-14.364-5.839-3.17-3.264-4.841-7.495-4.716-11.914l4.747-154.891c.297-9.681 9.244-17.55 19.953-17.55h55.09c6.34 0 12.24 2.795 15.77 7.479 3.11 4.122 4.09 9.306 2.68 14.224l-44.17 154.907c-2.28 7.995-10.23 13.584-19.33 13.584Zm-10.04-183.324c-7.025 0-12.895 4.887-13.067 10.883l-4.747 154.907c-.078 2.529.906 4.981 2.764 6.917 2.311 2.389 5.746 3.763 9.43 3.763h15.66c5.95 0 11.31-3.623 12.73-8.603l44.17-154.907c.81-2.873.26-5.778-1.56-8.198-2.25-2.966-6.09-4.746-10.29-4.746h-55.09v-.016Z"
    />
    <path
      fill="#fff"
      d="M928.575 781.825H886.48c-6.324 0-11.586 6.37-11.757 14.208L871.35 950.94c-.172 7.854 4.809 14.209 11.148 14.209h42.096c6.324 0 11.586-6.37 11.757-14.209l3.373-154.907c.172-7.853-4.809-14.208-11.149-14.208Z"
    />
    <path
      fill="currentColor"
      d="M924.574 968.601h-42.095c-3.529 0-6.933-1.484-9.572-4.169-3.294-3.373-5.121-8.307-5.012-13.553l3.373-154.907c.219-9.697 7.026-17.582 15.192-17.582h42.096c3.529 0 6.932 1.483 9.571 4.169 3.295 3.373 5.122 8.307 5.012 13.553l-3.372 154.907c-.219 9.696-7.027 17.582-15.193 17.582Zm-38.098-183.325c-4.388 0-8.197 4.965-8.322 10.852l-3.373 154.907c-.078 3.404 1.031 6.542 3.06 8.587.937.969 2.514 2.108 4.653 2.108h42.096c4.387 0 8.197-4.965 8.322-10.851l3.373-154.907c.078-3.404-1.031-6.543-3.045-8.604-.937-.952-2.514-2.108-4.653-2.108h-42.095l-.016.016ZM705.932 720.368c11.737-11.579 12.116-30.227.846-41.652s-29.922-11.3-41.66.28c-11.737 11.579-12.116 30.227-.846 41.652 11.27 11.424 29.922 11.299 41.66-.28Z"
    />
    <path
      fill="#fff"
      d="M685.201 711.451c-2.889 0-5.762-1.061-7.979-3.2-4.622-4.466-4.637-11.914-.047-16.661l205.918-212.632c4.591-4.731 12.055-4.965 16.661-.499 4.622 4.465 4.637 11.913.047 16.66L693.882 707.751a12.056 12.056 0 0 1-8.681 3.7Z"
    />
    <path
      fill="currentColor"
      d="M685.197 714.886c-3.903 0-7.588-1.483-10.367-4.169-2.842-2.748-4.45-6.464-4.528-10.477-.078-4.122 1.483-8.041 4.387-11.039l205.903-212.632c5.902-6.09 15.551-6.355 21.531-.594 2.858 2.764 4.466 6.48 4.528 10.477.079 4.122-1.483 8.042-4.387 11.04L696.361 710.124c-2.919 3.013-6.995 4.746-11.148 4.746l-.016.016Zm206.559-236.194c-2.233 0-4.497.89-6.215 2.655L679.639 693.979c-1.624 1.67-2.498 3.856-2.452 6.136a7.911 7.911 0 0 0 2.436 5.637c3.154 3.044 8.65 2.841 11.804-.422l205.918-212.632c1.624-1.671 2.499-3.857 2.452-6.136a7.97 7.97 0 0 0-2.436-5.653c-1.546-1.499-3.56-2.232-5.605-2.232v.015ZM1146.64 720.457c11.74-11.58 12.12-30.228.85-41.653-11.27-11.424-29.93-11.299-41.66.28-11.74 11.579-12.12 30.227-.85 41.652s29.92 11.3 41.66-.279Z"
    />
    <path
      fill="#fff"
      d="M1125.87 711.452c2.89 0 5.82-1.062 8.17-3.201 4.85-4.466 5.29-11.913.95-16.66L940.593 478.959c-4.325-4.731-11.773-4.966-16.644-.5-4.856 4.466-5.294 11.914-.953 16.66l194.394 212.632c2.25 2.467 5.34 3.701 8.48 3.701Z"
    />
    <path
      fill="currentColor"
      d="M1125.88 714.887c-4.2 0-8.22-1.749-11.03-4.825L920.458 497.43c-5.606-6.12-5.075-15.77 1.171-21.5 3.076-2.827 7.026-4.247 11.164-4.091 3.997.172 7.666 1.889 10.336 4.809L1137.52 689.28c5.61 6.121 5.08 15.77-1.17 21.501-2.89 2.638-6.6 4.106-10.47 4.106ZM932.106 478.693c-2.139 0-4.2.812-5.824 2.296-3.451 3.169-3.794 8.462-.75 11.804l194.398 212.632c2.93 3.216 8.43 3.357 11.77.297 3.45-3.17 3.79-8.463.73-11.805L938.039 481.285a8.001 8.001 0 0 0-5.574-2.576h-.375l.016-.016ZM704.251 562.942c40.25-16.672 59.363-62.815 42.691-103.065-16.672-40.249-62.815-59.362-103.064-42.69-40.25 16.671-59.363 62.815-42.691 103.064 16.672 40.249 62.815 59.363 103.064 42.691Z"
    />
    <path
      fill="#fff"
      d="M665.662 537.357c-3.044.172-5.839-1.202-7.338-3.638l-28.949-40.19c-2.342-3.795-.874-8.932 3.264-11.477 4.137-2.545 9.384-1.545 11.726 2.249l21.5 28.121 33.352-58.288c2.358-4.122 7.62-5.886 11.742-3.934 4.122 1.951 5.558 6.885 3.201 11.008l-40.878 71.418c-1.53 2.686-4.403 4.481-7.495 4.7h-.14l.015.031ZM245.133 1578.81l4.803 46.12 30.978 3.66v-54.69l-35.781 4.91Z"
    />
    <path
      fill="currentColor"
      d="M280.915 1630.65c-.085 0-.16 0-.244-.02l-30.979-3.65a2.062 2.062 0 0 1-1.814-1.84l-4.812-46.12a2.073 2.073 0 0 1 1.776-2.27l35.791-4.91c.592-.08 1.194.1 1.645.49.451.4.705.96.705 1.56v54.69c0 .59-.254 1.15-.696 1.55-.376.34-.874.52-1.372.52Zm-29.09-7.58 27.022 3.18v-49.98l-31.449 4.32 4.427 42.47v.01Z"
    />
    <path
      fill="#fff"
      d="M285.793 1708.87c-12.915 1.83-34.09 3.44-58.95-.03-8.13-1.13-13.525-9.09-11.561-17.1 5.47-22.39 14.897-64.86 31.082-84.56l4.023 10.68 5.555-10.05a4.93 4.93 0 0 1 3.675-2.5l12.162-1.55c2.537-.32 4.906 1.38 5.432 3.89l1.937 9.26 7.415-12.14 3.29 45.35c.705 9.72 2.162 19.36 4.361 28.84l3.318 14.31c.987 7.57-4.23 14.54-11.739 15.61v-.01Z"
    />
    <path
      fill="currentColor"
      d="M256.515 1712.95c-9.07 0-19.174-.56-29.954-2.07a15.948 15.948 0 0 1-10.978-6.84 16.079 16.079 0 0 1-2.312-12.81l1.174-4.85c5.583-23.12 14.945-61.82 30.321-80.53a2.02 2.02 0 0 1 1.936-.72c.734.12 1.335.62 1.598 1.31l2.444 6.48 3.384-6.13a7.021 7.021 0 0 1 5.225-3.56l12.163-1.55c3.581-.45 6.974 1.97 7.716 5.51l.865 4.15 4.69-7.68a2.056 2.056 0 0 1 2.256-.93c.874.22 1.513.97 1.579 1.86l3.289 45.35a187.93 187.93 0 0 0 4.314 28.54l3.318 14.3c1.175 8.86-4.887 16.91-13.459 18.12-7.604 1.08-17.717 2.04-29.578 2.04l.009.01Zm-10.762-101.55c-13.487 18.92-22.05 54.35-27.275 75.96l-1.175 4.86c-.799 3.26-.17 6.73 1.71 9.5a11.8 11.8 0 0 0 8.121 5.06c24.409 3.41 45.218 1.9 58.377.03 6.344-.89 10.818-6.86 9.972-13.29l-3.28-14.11a189.42 189.42 0 0 1-4.409-29.16l-2.829-38.91-4.051 6.63a2.05 2.05 0 0 1-2.114.96 2.064 2.064 0 0 1-1.673-1.61l-1.936-9.26c-.311-1.48-1.673-2.46-3.14-2.26l-12.162 1.55a2.88 2.88 0 0 0-2.133 1.46l-5.555 10.05a2.074 2.074 0 0 1-3.75-.27l-2.707-7.19h.009Z"
    />
    <path
      fill="currentColor"
      d="M257.474 1699.26c-7.688 0-16.833-.8-27.116-3.16a2.057 2.057 0 0 1-1.55-2.48c.253-1.12 1.362-1.83 2.481-1.55 30.123 6.9 50.547-.22 50.754-.3a2.073 2.073 0 0 1 2.651 1.25 2.081 2.081 0 0 1-1.241 2.65c-.583.21-10.189 3.59-25.969 3.59h-.01ZM242.51 1663.82a2.07 2.07 0 0 1-2.067-2 2.053 2.053 0 0 1 2.002-2.13l29.033-.97c1.156-.13 2.096.85 2.133 2a2.053 2.053 0 0 1-2.002 2.13l-29.033.97h-.075.009Z"
    />
    <path
      fill="#fff"
      d="m390.225 1554.26 4.643 67.3h37.276l1.739-67.3h-43.658Z"
    />
    <path
      fill="currentColor"
      d="M432.144 1623.62h-37.276a2.068 2.068 0 0 1-2.068-1.92l-4.643-67.3c-.038-.57.16-1.14.554-1.55.395-.42.94-.66 1.514-.66h43.648c.555 0 1.09.23 1.485.62.395.41.602.94.592 1.5l-1.729 67.29a2.075 2.075 0 0 1-2.068 2.02h-.009Zm-35.34-4.13h33.328l1.626-63.16h-39.306l4.361 63.16h-.009Z"
    />
    <path
      fill="#fff"
      d="M385.13 1659.55c-.094 6.36 4.23 11.95 10.386 13.4 21.43 5.02 69.702 16.19 83.472 18.26 17.867 2.68 39.832-2.83 49.278-13.36 9.446-10.51-21.147-35.65-35.659-44.78-21.561-13.58-42.286-40.42-50.97-41.63-8.685-1.2-24.907 24.17-24.907 24.17l-26.505-11.21s-4.625 22.87-5.095 55.15Z"
    />
    <path
      fill="currentColor"
      d="M488.33 1693.96c-3.261 0-6.495-.23-9.643-.7-13.939-2.09-63.133-13.49-83.641-18.29-7.049-1.66-12.096-8.15-11.984-15.44.48-32.11 5.095-55.31 5.142-55.53a2.075 2.075 0 0 1 2.838-1.5l24.907 10.54c4.295-6.49 17.275-24.83 25.979-23.63 5.019.7 12.087 7.45 21.871 16.8 9.07 8.65 19.343 18.47 29.917 25.13 11.692 7.36 36.43 26.86 38.291 40.14.423 3.07-.31 5.68-2.19 7.78-8.027 8.94-25.011 14.71-41.477 14.71l-.01-.01Zm-101.132-34.38c-.075 5.37 3.618 10.14 8.788 11.36 20.452 4.79 69.514 16.16 83.312 18.23 17.811 2.67 38.648-2.9 47.427-12.69 1.043-1.15 1.419-2.61 1.165-4.43-1.428-10.24-22.059-28.19-36.392-37.21-10.931-6.89-21.364-16.86-30.575-25.64-8.168-7.81-15.884-15.17-19.578-15.69-5.085-.68-16.439 13.16-22.877 23.22a2.05 2.05 0 0 1-2.547.79l-24.127-10.19c-1.175 6.75-4.22 26.61-4.596 52.26v-.01Z"
    />
    <path
      fill="currentColor"
      d="M488.058 1677.46c-32.482 0-74.449-14.85-89.75-20.72a2.082 2.082 0 0 1-1.194-2.68 2.086 2.086 0 0 1 2.679-1.2c14.474 5.56 88.087 32.34 115.682 14.26a2.072 2.072 0 0 1 2.867.59c.629.96.357 2.24-.593 2.87-7.547 4.95-18.008 6.87-29.681 6.87l-.01.01ZM446.656 1640.84a2.03 2.03 0 0 1-1.476-.62 2.062 2.062 0 0 1 .028-2.92l15.443-15.12a2.08 2.08 0 0 1 2.923.02c.799.82.78 2.13-.028 2.93l-15.443 15.12c-.404.39-.921.59-1.447.59Z"
    />
    <path
      fill="#fff"
      d="M234.558 1115.53s-11.551 210.38-17.595 283.01c-6.043 72.62 14.136 189.37 14.136 189.37l57.54-3.05s3.948-141.94 8.215-181.36c0 0 24.306-53.43 31.505-101.09 7.2-47.67 31.74-122.94 31.74-122.94s19.926 375.72 21.843 405.24c0 0 45.115 2.02 61.291-8.22 0 0 28.666-137 24.55-211.54-4.117-74.55.404-259.73.404-259.73l-233.638 10.31h.009Z"
    />
    <path
      fill="currentColor"
      d="M231.099 1589.98a2.08 2.08 0 0 1-2.039-1.73c-.207-1.16-20.151-117.83-14.155-189.89 5.968-71.77 17.473-280.85 17.595-282.95a2.07 2.07 0 0 1 1.974-1.96l233.638-10.31c.592-.05 1.128.19 1.541.59.414.41.639.96.62 1.53-.047 1.86-4.474 185.97-.404 259.57 4.079 73.95-24.296 210.71-24.587 212.08a2.051 2.051 0 0 1-.921 1.33c-16.542 10.47-60.623 8.61-62.494 8.54a2.074 2.074 0 0 1-1.974-1.94c-1.71-26.28-17.886-330.73-21.241-393.87-7.002 22.59-22.755 75.5-28.234 111.75-6.88 45.52-29.541 96.87-31.534 101.33-4.201 39.33-8.13 179.45-8.167 180.87a2.072 2.072 0 0 1-1.965 2.01l-57.54 3.05h-.113Zm5.424-472.47c-1.166 21.09-11.824 213.02-17.501 281.2-5.546 66.62 11.307 171.95 13.807 187.03l53.79-2.85c.488-17.07 4.21-143.06 8.177-179.61.028-.22.084-.44.169-.64.244-.54 24.268-53.74 31.345-100.55 7.143-47.28 31.571-122.52 31.816-123.27.31-.95 1.268-1.55 2.237-1.41.986.13 1.738.95 1.795 1.95.197 3.69 19.249 362.93 21.73 403.34 8.44.23 43.357.65 57.503-7.52 2.434-11.88 28.206-139.81 24.324-210.12-3.835-69.36-.141-236.46.348-257.68l-229.55 10.13h.01Z"
    />
    <path
      fill="currentColor"
      d="M286.262 1405.56h-17.585a2.068 2.068 0 0 1-2.068-2.07c0-1.14.931-2.07 2.068-2.07h17.585c1.147 0 2.068.93 2.068 2.07 0 1.14-.93 2.07-2.068 2.07ZM411.539 1514.83h-.038a2.068 2.068 0 0 1-2.03-2.1c.639-34.81 38.545-97.14 40.162-99.78a2.073 2.073 0 0 1 2.847-.69c.978.59 1.288 1.87.687 2.85-.386.64-38.94 64-39.551 97.69a2.065 2.065 0 0 1-2.068 2.03h-.009Z"
    />
    <path
      fill="#fff"
      d="M503.66 782.703c3.186-.254 29.71 45.274 42.173 67.005 3.543 6.184 11.447 8.186 17.491 4.455l97.495-60.191c7.961-4.916 14.794-11.495 19.935-19.334 8.139-12.406 12.989-25.019 23.882-33.836 8.864-7.171 17.84-15.132 20.528-17.218 1.682-1.307 9.502 4.257 3.609 14.145-1.814 3.036-8.572 9.192-10.424 11.984-2.951 4.455-2.951 10.395.329 14.605 3.497 4.493 6.42 11.702 2.491 21.721-5.808 14.832-18.976 18.497-26.43 19.362a15.13 15.13 0 0 0-9.671 5.094l-89.966 103.097c-13.958 15.997-37.295 19.606-55.379 8.562-29.249-17.867-66.911-53.874-94.045-99.393l57.972-40.096.01.038Z"
    />
    <path
      fill="currentColor"
      d="M562.347 930.642c-8.121 0-16.317-2.19-23.695-6.692-26.665-16.289-65.557-51.14-94.741-100.099a2.078 2.078 0 0 1 .601-2.763l57.973-40.096a2.069 2.069 0 0 1 1.015-.357c1.955-.131 4.897-.348 44.137 68.039 1.438 2.5 3.76 4.267 6.533 4.972a10.37 10.37 0 0 0 8.073-1.241l97.495-60.19c7.717-4.766 14.39-11.232 19.296-18.704 2.35-3.581 4.455-7.228 6.476-10.753 5.056-8.788 9.831-17.077 17.839-23.553 5.122-4.136 10.179-8.459 14.239-11.937 2.876-2.462 5.151-4.399 6.326-5.31 1.466-1.137 3.731-.705 5.761 1.09 3.111 2.745 5.207 8.515.893 15.753-1.09 1.823-3.553 4.427-5.94 6.936-1.861 1.955-3.778 3.985-4.54 5.132-2.547 3.844-2.443 8.741.235 12.19 3.666 4.718 7.125 12.661 2.782 23.751-6.212 15.866-20.292 19.757-28.121 20.659a13.112 13.112 0 0 0-8.356 4.399l-89.966 103.096c-8.957 10.264-21.552 15.659-34.325 15.659l.01.019ZM448.46 823.4c28.676 47.436 66.403 81.179 92.344 97.025 17.238 10.536 39.419 7.106 52.738-8.158l89.975-103.097c2.839-3.242 6.739-5.301 10.997-5.789 6.908-.799 19.315-4.211 24.747-18.065 2.914-7.444 2.181-14.08-2.199-19.7-3.778-4.859-3.948-11.693-.423-17.022.959-1.447 2.914-3.515 4.991-5.695 2.124-2.247 4.53-4.784 5.385-6.213 2.519-4.22 2.04-7.125 1.203-8.816-.657-1.335-1.654-2.077-2.18-2.312-1.194.958-3.243 2.706-5.762 4.859-3.872 3.308-9.164 7.829-14.324 12.002-7.406 5.987-11.993 13.958-16.852 22.398-2.058 3.581-4.182 7.275-6.607 10.959-5.235 7.98-12.35 14.878-20.584 19.954l-97.495 60.19c-3.402 2.096-7.406 2.717-11.269 1.739a14.517 14.517 0 0 1-9.108-6.927c-24.728-43.094-36.853-61.845-40.368-65.538l-55.209 38.187v.019Z"
    />
    <path
      fill="currentColor"
      d="M169.039 867.368s-6.485-119.855 84.12-153.521c70.163-26.073 159.754-14.823 187.838-5.085 69.138 23.976 103.162 115.324 103.162 115.324l-81.019 11.42s24.419 262.444 13.826 320.984c0 0-65.2 7.65-92.861-3.31-27.652-10.96-118.586-26.33-162.056-6.22l7.895-280.72-60.914 1.128h.009Z"
    />
    <path
      fill="#fff"
      d="M281.968 1091.18c-.987 0-1.861-.71-2.04-1.72a2.068 2.068 0 0 1 1.673-2.39c103.482-18.41 133.699-90.514 133.991-91.237a2.077 2.077 0 0 1 2.697-1.147 2.067 2.067 0 0 1 1.147 2.688c-.301.752-31.261 74.936-137.111 93.766a2.28 2.28 0 0 1-.367.03l.01.01ZM475.274 963.914H342.533l41.581-149.246h132.741l-41.581 149.246Z"
    />
    <path
      fill="currentColor"
      d="M475.274 965.981h-132.74c-.649 0-1.26-.3-1.645-.817a2.072 2.072 0 0 1-.348-1.805l41.581-149.254a2.064 2.064 0 0 1 1.992-1.514h132.741c.649 0 1.26.301 1.645.818.395.517.517 1.184.348 1.805l-41.581 149.254a2.065 2.065 0 0 1-1.993 1.513Zm-130.015-4.135h128.446l40.424-145.11H385.684l-40.425 145.11Z"
    />
    <path
      fill="#fff"
      d="m184.764 867.481 58.376-2.65s.442 49.485 5.705 50.368c18.761 3.149 58.057 3.309 83.641.198 8.91-1.081 15.96-7.942 17.633-16.815 1.269-6.701 4.868-14.568 14.013-20.236 8.835-5.47.123 11.749 8.384 22.84 4.437 5.958 18.497 3.317 37.662-1.157 19.164-4.464 24.033 16.148 2.556 28.611-23.29 13.506-38.084 11.993-45.509 13.572-14.794 3.148-145.589 23.788-165.778 12.735-15.602-8.534-16.683-87.475-16.683-87.475v.009Z"
    />
    <path
      fill="currentColor"
      d="M226.814 960.276c-12.284 0-21.796-.996-26.364-3.496-16.194-8.854-17.642-81.056-17.754-89.262a2.061 2.061 0 0 1 1.973-2.096l58.377-2.65c.555-.056 1.109.179 1.513.564.404.385.639.921.649 1.485.188 21.016 1.889 45.18 4.323 48.395 19.042 3.12 57.531 3.186 82.701.132 7.952-.969 14.324-7.05 15.847-15.142 1.729-9.145 6.899-16.617 14.953-21.608 1.448-.893 3.581-1.974 5.433-.931 2.19 1.232 2.162 4.117 2.134 8.102-.038 4.756-.094 11.269 3.571 16.185 3.732 5.01 18.3 2.087 35.537-1.927 10.49-2.453 16.336 1.993 18.309 6.965 2.67 6.729-.657 17.557-14.248 25.452-18.695 10.846-31.853 12.19-40.557 13.074-2.189.226-4.088.414-5.564.733-7.397 1.579-94.139 16.025-140.833 16.025Zm-39.945-90.812c.47 22.971 3.994 77.353 15.564 83.678 19.343 10.574 149.283-9.737 164.35-12.951 1.691-.357 3.693-.564 6.006-.799 8.355-.855 20.978-2.143 38.902-12.538 11.476-6.655 14.512-15.208 12.481-20.34-1.701-4.304-6.889-6.005-13.525-4.464-19.991 4.662-34.55 7.416-39.795.376-4.492-6.034-4.427-13.657-4.389-18.694.009-1.429.028-3.281-.141-4.221a8.102 8.102 0 0 0-1.109.593c-7.143 4.426-11.542 10.771-13.064 18.854-1.861 9.869-9.663 17.303-19.419 18.487-25.63 3.111-64.993 3.017-84.223-.216-1.701-.282-6.833-1.146-7.407-50.237l-54.222 2.463-.009.009Z"
    />
    <path
      fill="#fff"
      d="M385.778 710.905s-15.113-9.305-14.963-14.737l-4.314-27.915c-.047-2.557 1.532-4.85 3.91-5.687l17.877-6.137c2.274-.808 3.75-3.055 3.59-5.498l-1.391-22.182 7.002-6.203a1.366 1.366 0 0 0 .151-1.889l-11.063-13.488c-3.618-5.094-7.246-17.284-10.216-16.457 0 0-63.058-7.284-66.131 24.512-.442 4.606.94 9.202 3.609 12.961l5.583 7.877c5.611 7.904 8.562 17.594 7.566 27.285-.207 2.011-2.829 14.972-4.577 23.544a20.594 20.594 0 0 1-7.613 12.209c-4.925 3.797-10.922 8.628-9.944 8.938 0 0 6.983 5.132 18.008 9.672 19.578 8.074 41.844 6.062 59.927-4.982l2.999-1.832-.01.009Z"
    />
    <path
      fill="currentColor"
      d="M347.731 724.674c-8.741 0-17.463-1.663-25.659-5.047-11.128-4.586-18.149-9.7-18.441-9.916-.46-.338-.827-.874-.921-1.419-.244-1.41-.385-2.19 10.828-10.827a18.569 18.569 0 0 0 6.852-10.988c3.759-18.356 4.464-22.501 4.549-23.337.911-8.863-1.645-18.056-7.191-25.875l-5.582-7.877c-3.046-4.295-4.465-9.389-3.976-14.361.63-6.514 3.618-11.965 8.882-16.223 17.557-14.192 55.03-10.63 59.213-10.179 3.242-.47 5.414 4.145 8.102 9.925 1.287 2.783 2.631 5.649 3.891 7.426l10.978 13.365a3.43 3.43 0 0 1-.376 4.756l-6.25 5.536 1.334 21.175c.207 3.356-1.833 6.467-4.972 7.576l-17.886 6.137c-1.485.527-2.491 1.955-2.509 3.572l4.285 27.755c.019.122.029.244.029.376-.066 2.538 7.171 8.722 13.985 12.914.611.376.987 1.043.987 1.767s-.376 1.391-.996 1.767l-2.998 1.823a69.452 69.452 0 0 1-36.13 10.17l-.028.009Zm-39.974-17.162c2.66 1.739 8.318 5.169 15.885 8.28 18.741 7.726 40.443 5.922 58.066-4.831l.179-.112c-5.001-3.365-13.046-9.54-13.14-14.531l-4.286-27.755c-.019-.094-.019-.188-.028-.282-.057-3.449 2.068-6.542 5.291-7.679l17.887-6.147c1.381-.489 2.293-1.889 2.199-3.412l-1.391-22.181a2.078 2.078 0 0 1 .695-1.683l6.504-5.752-10.639-12.961c-1.57-2.199-2.989-5.244-4.352-8.196-1.165-2.509-3.092-6.645-4.145-7.509-.113 0-.235 0-.348-.01-.394-.047-39.597-4.38-56.468 9.258-4.427 3.581-6.843 7.961-7.369 13.403-.385 3.976.771 8.083 3.233 11.561l5.583 7.876c6.128 8.638 8.948 18.826 7.933 28.695-.169 1.616-1.72 9.606-4.615 23.741a22.71 22.71 0 0 1-8.374 13.432c-4.756 3.665-7.125 5.686-8.3 6.776v.019Z"
    />
    <path
      fill="currentColor"
      d="M378.457 542.326c-14.371-.743 3.797 14.07-49.429-1.231-21.195-6.091-10.311 18.779-15.809 17.209-20.32-5.78-19.625-5.611-29.503-2.914-6.542 1.786-10.715 14.024-9.408 23.131 1.72 12.031 15.207 31.12 27.294 51.309 6.26 10.451 17.332 11.401 30.734-4.154 1.429-1.655-5.865-11.411-2.058-15.612 9.888-10.931 14.277 5.583 22.266.479 7.66-4.897 10.828-8.055 18.929-15.461 9.804-8.957 26.252 4.981 31.929-15.17 2.96-10.508-5.264-36.59-24.936-37.595l-.009.009Z"
    />
    <path
      fill="currentColor"
      d="M357.47 644.558c-9.85 0-19.071-5.169-23.545-13.779l-4.934-9.493a2.07 2.07 0 1 1 3.675-1.908l4.934 9.493c4.69 9.023 15.659 13.535 26.092 10.734l8.938-2.679a2.063 2.063 0 0 1 2.575 1.391 2.063 2.063 0 0 1-1.391 2.575l-8.994 2.689a28.365 28.365 0 0 1-7.35.977Z"
    />
    <path
      fill="#fff"
      d="M326.002 597.911a2.066 2.066 0 0 1-2.039-1.739c-.151-.958-3.609-23.544 9.041-38.103 6.946-7.989 17.36-11.955 30.857-11.673a2.068 2.068 0 0 1-.038 4.135h-.037c-12.284-.253-21.542 3.215-27.642 10.236-11.373 13.083-8.121 34.531-8.093 34.748a2.071 2.071 0 0 1-2.049 2.396Z"
    />
    <path
      fill="#fff"
      d="M355.187 610.788c1.278-9.38-5.207-17.868-14.484-18.948-1.015-.123-4.794-.207-5.781-.151-8.054.498-12.199 6.805-13.346 15.161-1.231 9.004 2.472 17.416 11.166 19.023.357.066 2.932.254 3.308.254 10.17.028 17.858-5.959 19.146-15.339h-.009Z"
    />
    <path
      fill="currentColor"
      d="M336.125 628.195h-.084c-.442 0-3.149-.198-3.675-.292-9.192-1.691-14.352-10.263-12.839-21.335 1.4-10.254 6.965-16.429 15.264-16.946 1.146-.066 5.056.028 6.147.159 5.009.583 9.455 3.102 12.538 7.068 3.13 4.042 4.464 9.089 3.759 14.221-1.4 10.254-9.887 17.125-21.119 17.125h.009Zm-.169-34.457c-.394 0-.705 0-.902.019-7.998.498-10.63 7.547-11.42 13.375-.996 7.265 1.551 15.245 9.493 16.702.348.047 2.585.216 2.951.225h.076c9.201 0 15.874-5.31 17.002-13.553.545-4.023-.488-7.97-2.932-11.128a14.33 14.33 0 0 0-9.747-5.489c-.677-.075-3.073-.151-4.511-.151h-.01Z"
    />
    <path
      fill="#fff"
      d="M345.111 610.478c1.081-9.399-5.583-17.764-14.888-18.685-9.305-.921-17.726 5.959-18.807 15.357-1.081 9.399 5.583 17.764 14.888 18.685 9.305.922 17.726-5.958 18.807-15.357Z"
    />
    <path
      fill="currentColor"
      d="M327.966 627.988c-.62 0-1.24-.028-1.87-.094-5.019-.498-9.521-2.923-12.679-6.843-3.205-3.975-4.653-8.994-4.061-14.136 1.213-10.508 10.659-18.233 21.063-17.181 5.019.498 9.521 2.923 12.679 6.843 3.215 3.975 4.653 9.004 4.061 14.136-1.137 9.868-9.55 17.275-19.202 17.275h.009Zm.592-34.212c-7.575 0-14.192 5.836-15.085 13.609-.46 4.032.658 7.961 3.168 11.063a14.404 14.404 0 0 0 9.868 5.32c8.168.817 15.593-5.264 16.542-13.535.461-4.032-.667-7.961-3.167-11.072a14.402 14.402 0 0 0-9.869-5.319 15.278 15.278 0 0 0-1.457-.076v.01ZM370.326 649.22c5.472 0 9.907-4.46 9.907-9.963 0-5.502-4.435-9.963-9.907-9.963-5.471 0-9.906 4.461-9.906 9.963 0 5.503 4.435 9.963 9.906 9.963Z"
    />
  </svg>
);
const EmptyCart = memo(SvgComponent);
export default EmptyCart;
